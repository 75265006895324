const normalizes2Digit = int => (("0" + int).slice(-2));
export const timeToStr = (date) => {
  if (date) {
    const dateObj = new Date( Date.parse(date) )

    const year = dateObj.getFullYear();
    const month = normalizes2Digit(dateObj.getMonth() + 1);
    const day = normalizes2Digit(dateObj.getDate());
    const hours = normalizes2Digit(dateObj.getHours());
    const minutes = normalizes2Digit(dateObj.getMinutes());
    return `${year}/${month}/${day} ${hours}:${minutes}`
  }
}


export const RENTAL_HISTORY_LIMIT = 30;

export const generateMonthList = () => {
  const now = new Date();
  const monthList = [];

  for (let i = 0; i < RENTAL_HISTORY_LIMIT; i++) {
    const monthStr = dateToMonthStr(now);

    monthList.push({
      valueYearMonth: Number(monthStr),
      viewYearMonth: `${monthStr.slice(0, 4)}/${monthStr.slice(-2)}`
    });

    now.setMonth(now.getMonth() - 1);
  }

  return monthList;
};

export const getThisMonth = () => {
  const now = new Date();
  return dateToMonthStr(now);
};

export const dateToMonthStr = date => {
  const monthStr = ("0" + (date.getMonth() + 1)).slice(-2);
  return "" + date.getFullYear() + monthStr;
};
