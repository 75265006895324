import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = (): (() => any) =>
  makeStyles(() => ({
    text: {
      width: "1000px",
    }
  }));

const PushTextArea = (props: any) => {
  const { message, setMessage } = props;

  const classes: any = useStyles();

  return (
    <TextField
      className={classes.text}
      fullWidth
      value={message}
      onChange={e => setMessage(e.target.value)}
      placeholder='プッシュ通知する文言を入力してください。'
      multiline
      rows={10}
      rowsMax={100}
      variant='outlined'
    />
  );
};

export default PushTextArea;
