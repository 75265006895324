import React, { useState } from "react";
import {
  Button,
  FormControl,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { ConfirmDialog } from "components";

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

const useStyles: any = makeStyles((theme: any) => ({
  input: {
    minWidth: "450px",
    margin: "10px"
  },
  button: {
    margin: theme.spacing(2),
  },
}));

const KasaEdit = () => {
  const classes = useStyles();

  const [udpateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [kasa, setKasa] = useState({
    ksid: 0,
    pin: 0,
  });

  const getKasa = () => {
    if (kasa['ksid'] === 0) return;
    axios
      .get(`${API_HOST}/db/v1/kasa/master/${kasa['ksid']}`)
      .then(res => {
        setKasa(res.data);
      })
      .catch(err => {
        alert(`取得に失敗しました。\n${JSON.stringify(err.response)}`);
      });
  };

  const deleteKasa = () => {
    if (kasa['ksid'] === 0) return;
    axios
      .delete(`${API_HOST}/db/v1/kasa/master/${kasa['ksid']}`)
      .then(() => {
        alert('削除しました。');
      })
      .catch(err => {
        alert(`削除に失敗しました。\n${JSON.stringify(err.response)}`);
      });
  };

  const updateKasa = () => {
    if (kasa['ksid'] === 0) return;
    axios
      .put(`${API_HOST}/db/v1/kasa/master/${kasa['ksid']}`, kasa)
      .then(() => {
        alert('更新しました。');
      })
      .catch(err => {
        alert(`更新に失敗しました。。\n${JSON.stringify(err.response)}`);
      });
  };

  return (
    <div>
      <FormControl >
        <TextField
          className={classes.input}
          fullWidth
          type="text"
          label="傘ID"
          variant="outlined"
          value={kasa['ksid']}
          onChange={e => setKasa({ ...kasa, ksid: Number(e.target.value) })}
        />
        <TextField
          className={classes.input}
          fullWidth
          type="number"
          label="暗証番号"
          variant="outlined"
          value={kasa['pin']}
          onChange={e => setKasa({ ...kasa, pin: Number(e.target.value) })}
        />
      </FormControl>
      <div>
        <Button
          variant='outlined'
          color='primary'
          className={classes.button}
          onClick={getKasa}
        >取得</Button>
        <Button
          variant='outlined'
          color='primary'
          className={classes.button}
          onClick={() => setUpdateOpen(true)}>更新</Button>
        <Button
          variant='outlined'
          color='primary'
          className={classes.button}
          onClick={() => setDeleteOpen(true)}>削除</Button>
      </div>
      <ConfirmDialog
        onClose={() => setDeleteOpen(false)}
        open={deleteOpen}
        title="傘を削除しますか？"
        func={deleteKasa}
      />
      <ConfirmDialog
        onClose={() => setUpdateOpen(false)}
        open={udpateOpen}
        title="更新しますか？"
        func={updateKasa}
      />
    </div>
  );
};

export default KasaEdit;