import React, { useState } from "react";
import {
  Card,
  CardContent,
} from "@material-ui/core";
import { PushToolbar, PushTextArea } from './components';

const LinePush = props=> {
  const { user, ...rest } = props;

  const [ message, setMessage ] = useState("");

  return (
    <Card {...rest}>
      <CardContent>LINEプッシュ通知</CardContent>
      <PushToolbar user={user} message={message} setMessage={setMessage}/>
      <PushTextArea message={message} setMessage={setMessage}/>
    </Card>
  );
};

export default LinePush;