import {
  Button,
  Checkbox, FormControlLabel, FormGroup, TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";

const useStyles = makeStyles(theme => ({
  content: {
    padding: "10px"
  },
  input: {
    minWidth: "400px"
  },
  checkBox: {
    marginLeft: "10px"
  },
  button: {
    marginLeft: "auto",
    marginRight: "10px"
  }
}));

const UserInfo = (props: any) => {
  const { getUserDetails, } = props;

  const classes = useStyles();

  const [userId, setUserId] = useState("");
  const [isAlias, setIsAlias] = useState(true);

  return (
    <FormGroup row>
      <TextField
        label="ユーザーID or ユーザーID エイリアス"
        className={classes.input}
        value={userId}
        onChange={(e: any): void => setUserId(e.target.value)}
      />
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkBox}
            checked={isAlias}
            onClick={(): void => setIsAlias(!isAlias)}
            value="isAlias"
          />
        }
        label="alias"
      />
      <Button
        className={classes.button}
        onClick={() => getUserDetails(userId, isAlias)}
        variant="outlined"
        color="primary"
      >
        取得
      </Button>
    </FormGroup>
  );
};

export default UserInfo;
