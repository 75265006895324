import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import axios from "axios";

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: "10px"
  }
}));

const RentalHistoryTable = props => {
  const { user, month } = props;

  const classes = useStyles();

  const [amountInfo, setAmountInfo] = useState(null);

  useEffect(() => {
    if (user.ikasa_id === undefined) return;

    // const monthQuery = month ? month : "";

    axios
      .get(
        `${API_HOST}/service/v1/amount/month/${user.ikasa_id}`
      )
      .then(res => {
        setAmountInfo(res.data);
      })
      .catch(err => {
        alert("料金情報の取得に失敗しました。");
      });
    // eslint-disable-next-line
  }, [user, month]);

  return (
    <TableContainer className={classes.table} component={Paper}>
      {amountInfo ? (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>項目</TableCell>
              <TableCell>情報</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">当月料金</TableCell>
              <TableCell align="left">{amountInfo["total"]}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">キャンペーン</TableCell>
              <TableCell align="left">
                {amountInfo["campaign"]["list"]
                  .map(campaign => campaign["detail"].name)
                  .join(", ")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">プラン名</TableCell>
              <TableCell align="left">
                {amountInfo["user_info"]["plan_type"]}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">決済方法</TableCell>
              <TableCell align="left">
                {amountInfo["payment_info"]["pay_type"]}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">当月傘買取回数</TableCell>
              <TableCell align="left">
                {amountInfo["purchase"]["count"]}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">当月傘買取料金</TableCell>
              <TableCell align="left">
                {amountInfo["purchase"]["sub_total"]}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">当月トークン数</TableCell>
              <TableCell align="left">
                {amountInfo["charge"]["count"]}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">累計トークン数</TableCell>
              <TableCell align="left">
                {amountInfo["charge"]["accumulation_count"]}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">当月レンタル回数</TableCell>
              <TableCell align="left">
                {amountInfo["transaction"]["count"]}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">累計レンタル回数</TableCell>
              <TableCell align="left">
                {amountInfo["transaction"]["accumulation_count"]}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left">次回決済日</TableCell>
              <TableCell align="left">
                {amountInfo["settlement_date"]}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <></>
      )}
    </TableContainer>
  );
};

export default RentalHistoryTable;
