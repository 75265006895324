import React, { useEffect, useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  TextField,
  MenuItem,
  Box
} from "@material-ui/core";
import { ConfirmDialog } from '../../../../components'
import { refineSpots } from "utils/refine";

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

const useStyles = makeStyles(theme => ({
  row: {
    height: "84px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(2),
    minWidth: "90px"
  }
}));


const MoveUmbrellaToolbar = props => {
  const {
    className,
    scd,
    setScd,
    umbrellas,
    setUmbrellas,
    setSuccesses,
    setFailures,
    initialSuccess,
    initialFailure,
    ...rest
  } = props;

  const classes = useStyles();

  const [spots, setSpots] = useState([]);
  const [allSpots, setAllSpots] = useState([]);
  const [resetOpen, setResetOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const hendleChangeScd = e => {
    setScd(e.target.value);
    setSuccesses(initialSuccess);
    setFailures(initialFailure);
  };

  const handleSearch = (e) => {
    const refinedSpots = refineSpots(allSpots, e.target.value)
    setSpots(refinedSpots)
    if (refinedSpots.length > 0) { setScd(refinedSpots[0]['scd']) }
  }

  const resetAll = () => {
    setSuccesses(initialSuccess);
    setFailures(initialFailure);
    setUmbrellas([]);
    setResetOpen(false);
  };

  const updateStocks = () => {
    const data = { scd: scd, umbrellas: umbrellas };
    axios
      .put(`${API_HOST}/service/v1/maintenance/stocks`, data)
      .then(res => {
        setSuccesses(res.data.success);
        setFailures(res.data.failure);
      })
      .catch(err => {
        alert("更新に失敗しました。");
      });
    setUpdateOpen(false);
  };

  useEffect(() => {
    axios
      .get(`${API_HOST}/service/v1/maintenance/spots`)
      .then(res => {
        setSpots(res.data['spots']);
        setAllSpots(res.data['spots']);
      })
      .catch(err => {
        alert(`spots get error ${err}`);
      });
  }, []);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Box width="100%">
          <TextField
            label="検索"
            margin="dense"
            variant="outlined"
            onChange={handleSearch}
            fullWidth
          />

          <TextField
            select
            label="スポットコード"
            margin="dense"
            name="scd"
            value={scd}
            fullWidth
            // eslint-disable-next-line react/jsx-sort-props
            variant="outlined"
            onChange={hendleChangeScd}
          >
            {spots.map(spot => (
              <MenuItem key={spot.scd} value={spot.scd}>
                {`${spot.scd}. ${spot.spot_name}`}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          onClick={() => setUpdateOpen(true)}
        >
          更新
        </Button>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={() => setResetOpen(true)}
        >
          リセット
        </Button>

        <ConfirmDialog
          func={updateStocks}
          open={updateOpen}
          onClose={() => setUpdateOpen(false)}
          title={`${umbrellas.length}本をスポットコード：${scd}に更新しますか？`}
        />

        <ConfirmDialog
          func={resetAll}
          open={resetOpen}
          onClose={() => setResetOpen(false)}
          title="リセットしますか？"
        />
      </div>
    </div>
  );
};

export default MoveUmbrellaToolbar;
