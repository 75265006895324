import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  Box
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core'
import axios from "axios";
import { timeToStr } from "../../../../../utils/date";

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: "10px"
  },
}));

const TransactionTable = props => {
  const { user } = props;
  
  const classes = useStyles();

  const [Payments, setPayments] = useState([]);
  
  useEffect(() => {
    setPayments([]);
    if ( user.ikasa_id === undefined ) return
    
    axios
    .get(`${API_HOST}/service/v1/cs/payment/transactions/${user.ikasa_id}`)
    .then(res => {
      setPayments(res.data);
    })
    .catch(err => {
      console.log(err)      
    });
    // eslint-disable-next-line
  }, [user]);

  const onRefund = async (orderId) => {
    try {
      const dialogResult = window.confirm('本当に返金してもよろしいですか？')
      if (!dialogResult) {
        return
      }
      await axios.post(`${API_HOST}/service/v1/payment/refund`, {
        ikasa_id: user.ikasa_id,
        order_id: orderId,
      });
      window.alert('返金に成功しました');
    } catch (error) {
      window.alert('返金に失敗しました');
    }
  }

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell >支払日時</TableCell>
          <TableCell >支払方法</TableCell>
          <TableCell >金額</TableCell>
          <TableCell >オーダーID</TableCell>
          <TableCell >返金したか</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
            Payments.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell>{timeToStr(transaction["CreatedAt"])}</TableCell>
                <TableCell>{transaction.pay_type}</TableCell>
                <TableCell>{transaction.amount}</TableCell>
                <TableCell>
                  <Box display='flex' alignItems='center'>
                    <Typography style={{ marginRight: 4 }}>
                      {transaction.order_id}
                    </Typography>
                    <Button 
                      variant="contained" 
                      style={{ backgroundColor: '#f00', color: '#fff' }} 
                      onClick={() => onRefund(transaction.order_id)}
                    >
                      返金
                    </Button>
                  </Box>
                </TableCell>
                <TableCell>{transaction.is_refunded ? '済' : ''}</TableCell>
              </TableRow>
            )
          )
        }
      </TableBody>
    </Table>
  );
};

export default TransactionTable;
