import React, { useState } from "react";
import { Card, CardContent } from "@material-ui/core";

import { ReturnToolbar, TransactionTable } from "./components";

const Return = props => {
  const { user, ...rest } = props;

  const [scd, setScd] = useState(1);
  const [transactions, setTransactions] = useState([]);

  return (
    <Card {...rest}>
      <CardContent>返却処理</CardContent>
      <ReturnToolbar
        user={user}
        scd={scd}
        setScd={setScd}
        transactions={transactions}
        setTransactions={setTransactions}
      />
      <TransactionTable
        user={user}
        scd={scd}
        transactions={transactions}
        setTransactions={setTransactions}
      />
    </Card>
  );
};

export default Return;
