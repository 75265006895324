// CustomerSuccess.tsx
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {
  Amount,
  LinePush,
  Payments,
  RentalHistory,
  Return,
  Tokens,
  UserInfo,
  Coupon,
} from "./components";
import { fetchUser, fetchPayment, User, Payment } from "./cs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const CustomerSatisfaction: React.FC = () => {
  const classes = useStyles();

  const [user, setUser] = useState<User>({} as User);
  const [payment, setPayment] = useState<Payment>({} as Payment);

  const getUserDetailsWithQueryParam = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const userId = searchParams.get("ikasa_id");

    if (userId !== null) {
      await getUserDetails(userId);
    }
  };

  const getUserDetails = async (userId: string, isAlias = false) => {
    try {
      const user = await fetchUser(userId, isAlias);
      if (!user) return;
      setUser(user);
      const payment = await fetchPayment(user.ikasa_id);
      if (payment) {
        setPayment(payment);
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(`失敗しました。\nエラー：${error.message}`);
      } else {
        alert("An unknown error occurred.");
      }
    }
  };

  useEffect(() => {
    getUserDetailsWithQueryParam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <h3>CS</h3>
      <UserInfo
        className={classes.content}
        user={user}
        payment={payment}
        getUserDetails={getUserDetails}
      />
      <Return className={classes.content} user={user} />
      <RentalHistory className={classes.content} user={user} />
      <Payments className={classes.content} user={user} />
      <Coupon className={classes.content} user={user} />
      <Amount className={classes.content} user={user} />
      <Tokens className={classes.content} user={user} />
      <LinePush className={classes.content} user={user} />
    </div>
  );
};

export default CustomerSatisfaction;
