import { Button, Card, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Slide, Snackbar, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useRef, useState } from "react";
import firebase, { storage } from '../../../../firebase/firebase';
import { timeToStr } from '../../../../utils/date';
import { resizeImage } from '../../../../utils/image';
import prefectureOptions from '../../../../utils/prefectures';


function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function getPathStorageFromUrl(url) {

  const baseUrl = "https://firebasestorage.googleapis.com/v0/b/ikasa-1563170568813.appspot.com/o/";

  let imagePath = url.replace(baseUrl, "");

  const indexOfEndPath = imagePath.indexOf("?");

  imagePath = imagePath.substring(0, indexOfEndPath);

  imagePath = imagePath.replace("%2F", "/");


  return imagePath;
}
const useStyles = makeStyles(theme => ({
  card: {
    padding: 30
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2)
  },
  heading: {
    marginTop: '20px',
    marginLeft: '5px',
  },
  textField: {
    margin: '8px',
  },
  selectField: {
    margin: '8px',
  },
  button: {
    margin: '0px 10px 0px 10px  ',
  },
  image: {
    margin: '10px 10px 40px',
    height: '500px',
  },
  imageUploadForm: {
    margin: '10px 5px 0px auto',
  }
}));

const SpotInfo = props => {

  const classes = useStyles();

  const { spotInfo, setSpotInfo, isCreateAction, ...rest } = props;
  const [imageUploadingSnacbarOpen, setImageUploadingSnackbarOpen] = useState(false);
  const [isPrefectureIdValid, setIsPrefectureIdValid] = useState(true);
  const detailImageInputRef = useRef();

  const handleChangeValue = (e, target) => {
    setSpotInfo({ ...spotInfo, [target]: e.target.value });
  };

  const addItemToImageUrlsList = () => {
    const cpList = spotInfo['image_urls_list'].slice();
    cpList.push('');
    setSpotInfo({ ...spotInfo, image_urls_list: cpList });
  }

  const onChangedImageUrlsList = (value, index) => {
    const cpList = spotInfo['image_urls_list'].slice();
    cpList[index] = value;
    setSpotInfo({ ...spotInfo, image_urls_list: cpList });
  }


  const onChangedNewSpotImage = async (event) => {
    event.preventDefault();

    const image = event.target.files[0];

    if (image === '') {
      console.log('file is nothing');
      return;
    }

    setImageUploadingSnackbarOpen(true);

    const resizedImageInfo = await resizeImage(image);
    const imageName = Date.now().toString() + image.name;
    const imagePath = `spots/${spotInfo['scd']}/${imageName}`;
    const upLoadTask = storage.ref(imagePath).put(resizedImageInfo.imageFile);
    upLoadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      imageUploadNext,
      imageUploadError,
      () => { imageUploadCompleted(imagePath) },
    )
  }

  const prefectureIdSelectHandler = (e) => {
    setIsPrefectureIdValid(e.target.value !== 0);
    handleChangeValue(e, "prefecture_id");
  }

  const imageUploadNext = snapshot => {
    // 進行中のsnapshotを得る
    // アップロードの進行度を表示
    const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log(percent + "% done");
    console.log(snapshot);
  };

  const imageUploadError = error => {
    setImageUploadingSnackbarOpen(false);
    console.log(error);
  };

  const imageUploadCompleted = (imagePath) => {
    detailImageInputRef.current.value = null;
    setImageUploadingSnackbarOpen(false);
    storage
      .ref()
      .child(imagePath)
      .getDownloadURL()
      .then(imageUrl => {
        console.log('success');
        const cpSpotImageUrlsList = spotInfo['image_urls_list'].slice();
        cpSpotImageUrlsList.push(imageUrl);
        setSpotInfo({ ...spotInfo, image_urls_list: cpSpotImageUrlsList });
      })
      .catch(err => {
        console.log(err);
      });
  }

  return (
    <Card {...rest}>
      <FormControl
        fullWidth
      >
        <h2 className={classes.heading}>スポット情報</h2>
        <TextField
          className={classes.textField}
          type="number"
          variant="outlined"
          value={spotInfo['scd']}
          label={`スポットコード${isCreateAction ? '' : '（編集不可）'}`}
          onChange={e => setSpotInfo({ ...spotInfo, scd: Number(e.target.value) })}
          disabled={!isCreateAction}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          value={spotInfo['spot_id']}
          label="スポットID（編集不可）"
          disabled={true}
          onChange={e => handleChangeValue(e, 'spot_id')}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          value={spotInfo['ble_id']}
          label="BLE ID（編集不可）"
          disabled={true}
          onChange={e => handleChangeValue(e, 'ble_id')}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="スポット名"
          value={spotInfo["spot_name"]}
          onChange={e => handleChangeValue(e, "spot_name")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="エリア"
          value={spotInfo["area"]}
          onChange={e => handleChangeValue(e, "area")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <FormControl className={classes.selectField} variant="outlined" error={!isPrefectureIdValid}>
          <InputLabel id="prefecture-label">都道府県</InputLabel>
          <Select
            labelId="prefecture-label"
            id="prefecture-selection"
            value={spotInfo["prefecture_id"]}
            label="都道府県"
            onChange={prefectureIdSelectHandler}
          >
            {prefectureOptions.map((prefecture) => <MenuItem key={prefecture.id} value={prefecture.id}>{prefecture.name}</MenuItem>)}

          </Select>
        </FormControl>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          value={spotInfo["detail_url"]}
          label="詳細リンク"
          onChange={e => handleChangeValue(e, "detail_url")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="スポット画像"
          value={spotInfo["spot_img"]}
          onChange={e => handleChangeValue(e, "spot_img")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="タイプ"
          value={spotInfo["type"]}
          onChange={e => handleChangeValue(e, "type")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="業種タイプ"
          value={spotInfo["industry_type"]}
          onChange={e => handleChangeValue(e, "industry_type")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="number"
          variant="outlined"
          label="最大傘格納本数"
          value={spotInfo["kasa_max"]}
          onChange={e => setSpotInfo({ ...spotInfo, kasa_max: Number(e.target.value) })}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="シェア開始時間"
          value={spotInfo["share_start_time"]}
          onChange={e => handleChangeValue(e, "share_start_time")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>

        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="シェア終了時間"
          value={spotInfo["share_end_time"]}
          onChange={e => handleChangeValue(e, "share_end_time")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="開店時間"
          value={spotInfo["start_time"]}
          onChange={e => handleChangeValue(e, "start_time")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="閉店時間"
          value={spotInfo["end_time"]}
          onChange={e => handleChangeValue(e, "end_time")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <div>
          <TextField
            className={classes.textField}
            type="number"
            variant="outlined"
            label="経度"
            value={spotInfo['position']['lat']}
            onChange={e => setSpotInfo({
              ...spotInfo, position: {
                ...spotInfo.position, lat: Number(e.target.value)
              }
            })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            className={classes.textField}
            type="number"
            variant="outlined"
            label="経度"
            value={spotInfo['position']['lng']}
            onChange={e => setSpotInfo({
              ...spotInfo, position: {
                ...spotInfo.position, lng: Number(e.target.value)
              }
            })}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.textField}
            control={
              <Checkbox
                checked={spotInfo['is_hidden']}
                onChange={e => setSpotInfo({ ...spotInfo, is_hidden: e.target.checked })}
                name="is_hidden"
              />
            }
            label="マップ非表示"
          />
          <FormControlLabel
            className={classes.textField}
            control={
              <Checkbox
                checked={spotInfo['is_maintenance']}
                onChange={e => setSpotInfo({ ...spotInfo, is_maintenance: e.target.checked })}
                name="is_maintenance"
              />
            }
            label="メンテナンス中"
          />
        </div>
        <h2 className={classes.heading}>スポット詳細ページ情報</h2>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="スポット名詳細"
          value={spotInfo["spot_name_detail"]}
          onChange={e => handleChangeValue(e, "spot_name_detail")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="シェア開始時間詳細"
          value={spotInfo["share_time_detail"]}
          onChange={e => handleChangeValue(e, "share_time_detail")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="開店・閉店時間詳細"
          value={spotInfo["time_detail"]}
          onChange={e => handleChangeValue(e, "time_detail")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="休日"
          value={spotInfo["holiday"]}
          onChange={e => handleChangeValue(e, "holiday")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="サブカテゴリー"
          value={spotInfo["sub_category"]}
          onChange={e => handleChangeValue(e, "sub_category")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="置き場所"
          value={spotInfo["stand_place"]}
          onChange={e => handleChangeValue(e, "stand_place")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="住所"
          value={spotInfo["address"]}
          onChange={e => handleChangeValue(e, "address")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="URL"
          value={spotInfo["url"]}
          onChange={e => handleChangeValue(e, "url")}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="紹介文"
          value={spotInfo["introduction"]}
          onChange={e => handleChangeValue(e, "introduction")}
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rows={10}
          rowsMax={100}
        ></TextField>
        <div className={classes.row}>
          <h2 className={classes.heading}>詳細ページ画像</h2>
          <form className={classes.imageUploadForm}>
            <input type="file" onChange={onChangedNewSpotImage} ref={detailImageInputRef} />
          </form>
          <Button
            variant='outlined'
            color='primary'
            onClick={addItemToImageUrlsList}
            className={classes.button}
          >
            追加
          </Button>
        </div>
        {
          spotInfo['image_urls_list'].map((imageUrl, index) => {
            return (
              <div key={index}>
                <div className={classes.row}>
                  <TextField
                    className={classes.textField}
                    type="text"
                    variant="outlined"
                    value={imageUrl}
                    onChange={e => onChangedImageUrlsList(e.target.value, index)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  ></TextField>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => {
                      const cpList = spotInfo['image_urls_list'].slice();
                      cpList.splice(index, 1)
                      setSpotInfo({ ...spotInfo, image_urls_list: cpList });
                      const imagePath = getPathStorageFromUrl(imageUrl);
                      const decodedImagePath = decodeURIComponent(imagePath);
                      storage.ref().child(decodedImagePath).delete()
                        .then(() => console.log('deleted'))
                        .catch(() => console.log('image delete error'));
                    }}
                    className={classes.button}
                  >
                    削除
                  </Button>
                </div>
                <img className={classes.image} src={imageUrl} alt='スポットの画像' />
              </div>
            )
          })
        }

        <div style={{ marginTop: '80px' }}></div>

        <h2 className={classes.heading}>SNS URL</h2>
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="LINE"
          value={spotInfo['social_url']['line']}
          onChange={e => setSpotInfo({
            ...spotInfo, social_url: {
              ...spotInfo.social_url, line: e.target.value
            }
          })}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="Twitter"
          value={spotInfo['social_url']['twitter']}
          onChange={e => setSpotInfo({
            ...spotInfo, social_url: {
              ...spotInfo.social_url, twitter: e.target.value
            }
          })}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="Instagram"
          value={spotInfo['social_url']['instagram']}
          onChange={e => setSpotInfo({
            ...spotInfo, social_url: {
              ...spotInfo.social_url, instagram: e.target.value
            }
          })}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className={classes.textField}
          type="text"
          variant="outlined"
          label="Facebook"
          value={spotInfo['social_url']['facebook']}
          onChange={e => setSpotInfo({
            ...spotInfo, social_url: {
              ...spotInfo.social_url, facebook: e.target.value
            }
          })}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <div className={classes.textField}>
          <span>作成日時（編集不可）：</span>{timeToStr(spotInfo['created_at'])}
        </div>
        <div className={classes.textField}>
          <span>最終更新日時（編集不可）：</span>{timeToStr(spotInfo['updated_at'])}
        </div>
      </FormControl>

      <Snackbar
        open={imageUploadingSnacbarOpen}
        onClose={() => setImageUploadingSnackbarOpen(false)}
        TransitionComponent={TransitionUp}
        message="アップロード中..."
      />
    </Card>
  );
};

export default SpotInfo;
