import React from "react";
import { Card, CardContent } from "@material-ui/core";

import { CouponTable } from "./components";

const Coupon = (props) => {
  const { user, ...rest } = props;

  return (
    <Card {...rest}>
      <CardContent>クーポン情報</CardContent>
      <CardContent>クーポン一覧</CardContent>
      <CouponTable user={user} />
    </Card>
  );
};

export default Coupon;
