import React, { useState } from "react";
import {
  ConfirmDialog,
  ErrorDialog,
  SuccessDialog,
} from "../../../../../components";
import { withDraw } from "../../../cs";

import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: "20px",
  },
  text: {
    color: theme.palette.error.main,
    marginTop: "35px",
    marginBottom: "10px",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

interface UserDeleteProps {
  ikasaId: string;
}

const UserDelete: React.FC<UserDeleteProps> = ({ ikasaId }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleWithDraw = async () => {
    try {
      await withDraw(ikasaId);
      setOpen(false);
      setSuccessOpen(true);
    } catch (error) {
      setOpen(false);
      setErrorOpen(true);
      setErrorMessage(`退会処理に失敗しました。Reason: ${error}`);
    }
  };

  const handleClick = () => {
    if (ikasaId === "") {
      setErrorOpen(true);
      setErrorMessage("ユーザーIDが空です！");
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <div className={classes.flex}>
        <Button
          onClick={handleClick}
          className={classes.button}
          variant="outlined"
          color="primary"
        >
          退会処理を行う
        </Button>
      </div>
      <ConfirmDialog
        onClose={() => setOpen(false)}
        func={handleWithDraw}
        open={open}
        title="ユーザーを退会させますか？"
        message="退会処理を行う前にユーザの決済が完了しているか確認して下さい。"
      />
      <SuccessDialog
        onClose={() => setSuccessOpen(false)}
        open={successOpen}
        title="退会処理が完了しました。"
      />
      <ErrorDialog
        onClose={() => setErrorOpen(false)}
        open={errorOpen}
        title="エラー"
        errorMessage={errorMessage}
      />
    </>
  );
};

export default UserDelete;
