import React, { useEffect, useState } from "react";
import { Select, MenuItem, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { ConfirmDialog } from "../../../../../components/";

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: "auto",
    marginRight: "10px"
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center"
    // marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1
  }
}));

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

const CONSTANT_PHRASES = [
  { name: "1", message: `いつもご利用ありがとうございます。

傘◆◆につきまして、こちら返却処理がされないまま〇〇駅に置いてありましたのでこちらにてシステム返却させていただきました。
料金の修正を行わせていただきますので、お手数ですが問い合わせ窓口へ返却日時をご連絡いただけますでしょうか？` },
  { name: "2", message: "いつもご利用ありがとうございます。" }
];

const PushToolbar = props => {
  const classes = useStyles();

  const { user, message, setMessage } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setMessage(CONSTANT_PHRASES[0].message);
  }, [setMessage]);

  const linePush = () => {
    if (user["line_id"] === "") {
      alert("LINE IDが存在しません。");
      return;
    }

    axios
      .post(`${API_HOST}/service/v1/cs/push`, {
        line_id: user["line_id"],
        message: message
      })
      .then(res => {
        alert("プッシュ通知が完了しました。");
      })
      .catch(err => {
        alert("プッシュ通知に失敗しました。");
      });
  };

  const handlePhraseChange = e => {
    setMessage(CONSTANT_PHRASES[e.target.value].message);
  };

  return (
    <div className={classes.row}>
      <Select defaultValue={0} onChange={handlePhraseChange}>
        {CONSTANT_PHRASES.map((phrase, index) => (
          <MenuItem key={index} value={index}>
            {phrase.name}
          </MenuItem>
        ))}
      </Select>
      <div className={classes.spacer}></div>
      <Button color="primary" onClick={() => setOpen(true)} variant="outlined">
        送信
      </Button>
      <ConfirmDialog
        onClose={() => setOpen(false)}
        func={linePush}
        open={open}
        title="LINE Push通知を行いますか？"
      />
    </div>
  );
};

export default PushToolbar;
