import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import { FunctionCard } from "./components";
import functionsData from "./functions";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
}));

const FunctionsList = () => {
  const classes = useStyles();

  const [functions] = useState(functionsData);

  return (
    <div className={classes.root}>
      {/* <FunctionsToolbar /> */}
      <h1>メンテナンス</h1>
      <div className={classes.content}>
        <Grid container spacing={3}>
          {functions.map(func => (
            <Grid item key={func.id} lg={4} md={6} xs={12}>
              <RouterLink to={func.path}>
                <FunctionCard func={func} />
              </RouterLink>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default FunctionsList;
