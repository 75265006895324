import React, { useState } from "react";
import { Card, CardContent } from "@material-ui/core";

import { TokensToolbar, TokensTable } from "./components";

import { CHARGE } from '../../../../constants/plan'


const Tokens = props => {
  const { user, ...rest } = props;

  const [ planType, setPlanType ] = useState(CHARGE)

  return (
    <Card {...rest}>
      <CardContent>トークン編集</CardContent>
      <TokensToolbar planType={planType} setPlanType={setPlanType} />
      <TokensTable user={user} planType={planType} />
    </Card>
  );
};

export default Tokens;
