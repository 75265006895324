import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import axios from 'axios'
import { makeStyles } from "@material-ui/styles";
import { ConfirmDialog } from "components";

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

interface IResponse {
  data: {
    failed_count: number,
    failed_list: number[],
  }
}

const useStyles = makeStyles((theme: any) => ({
  card: {
    height: '100%',
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1)
  },
  input: {
    margin: "8px",
  },
  button: {
    marginLeft: theme.spacing(2),
  }
}));

const KasaRegsiterList = () => {
  const classes = useStyles();

  const [createKasaOpen, setCreateKasaOpen] = useState(false);
  const [kasasList, setKasasList]: any = useState([]);
  const [kasa, setKasa] = useState(
    { ksid: 0, pin: 0 }
  );

  const createKasa = () => {
    axios.post(`${API_HOST}/service/v1/maintenance/kasa`, kasasList)
      .then((res: IResponse) => {
        if (res.data['failed_count'] === 0) {
          alert('完了しました。')
        } else {
          alert(
            `${res.data['failed_count']} 件の登録に失敗しました。\n失敗対象：${JSON.stringify(res.data['failed_list'])}`
          )
        }
      })
      .catch(err => alert(err.response))
  }

  const isDuplicateKasa = (ksid: number): boolean => {
    for (const k of kasasList) {
      if (k['ksid'] === ksid) {
        return true
      }
    }
    return false
  }

  const addToKasasList = () => {
    if (kasa['ksid'] === 0 || kasa['pin'] === 0) return
    if (isDuplicateKasa(kasa['ksid'])) {
      alert('傘が重複しています。')
      return
    }
    const cpkasasList = kasasList.slice();
    cpkasasList.push(kasa);
    setKasasList(cpkasasList);
  };

  const deleteFromKasaList = (index: number) => {
    const cpKasasList = kasasList.slice();
    cpKasasList.splice(index, 1);
    setKasasList(cpKasasList);
  }

  return (
    <Card className={classes.card}>
      <CardContent>傘登録</CardContent>
      <Button
        className={classes.button}
        variant='outlined'
        color='primary'
        onClick={() => setCreateKasaOpen(true)}
      >登録</Button>
      <div>
        <TextField
          type='number'
          variant='outlined'
          color='primary'
          label='傘ID'
          value={kasa['ksid']}
          onChange={e => setKasa({ ...kasa, 'ksid': Number(e.target.value) })}
          className={classes.input}
        />
        <TextField
          type='number'
          variant='outlined'
          color='primary'
          label='暗証番号'
          value={kasa['pin']}
          onChange={e => setKasa({ ...kasa, 'pin': Number(e.target.value) })}
          className={classes.input}
        />
        <Button
          variant='outlined'
          color='primary'
          onClick={addToKasasList}
          style={{ marginTop: '10px', marginLeft: '42px' }}
        >追加</Button>
      </div>
      <List component="nav" aria-label="contacts">
        <Grid container>
          {
            kasasList.map((kasa: { [key: string]: number }, index: number) => {
              return (
                <ListItem key={index} >
                  <Grid item xs={5}>
                    <ListItemText>{kasa['ksid']}</ListItemText>
                  </Grid>
                  <Grid item xs={5}>
                    <ListItemText>{kasa['pin']}</ListItemText>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => deleteFromKasaList(index)}
                      className={classes.button}
                    >削除</Button>
                  </Grid>
                </ListItem>
              )
            })
          }
        </Grid>
      </List>
      <ConfirmDialog
        onClose={() => setCreateKasaOpen(false)}
        open={createKasaOpen}
        title="登録しますか？"
        func={createKasa}
      />
    </Card>
  )
};


export default KasaRegsiterList;
