import React , { useState } from "react";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  input: {
    margin:"0 10px"
  }
}));




const CreateOffice = () => {
  const classes = useStyles();
  const[officecode, setOfficeCode] = useState("");
  const[officeprice, setOfficePrice] = useState("");
  var price = parseInt(officeprice);
  
  
  const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

  const RegisterOffice = () => {
    
    axios
      .post(`${API_HOST}/db/v1/payment/office`,{
        office_code: officecode,
        price: price,
        })
      .then(results=>{
        alert(`オフィスコード:${officecode}料金:${price} 登録完了致しました。`);
        setOfficePrice("0");
        setOfficeCode("");
      })
      .catch(err => {
        alert(`登録に失敗しました`);
      });
  }


  return (
    <>
      <form>
        <h1>オフィス登録</h1>
        <TextField className={classes.input} 
                   label="オフィスコード" 
                   value={officecode} 
                   onChange={ e => setOfficeCode(e.target.value)}/>
        <TextField className={classes.input}  
                   label="料金" value={officeprice} 
                   onChange={ e => setOfficePrice(e.target.value)}/>
        <Button variant="outlined" color="primary" onClick={()=>{RegisterOffice()}}>登録</Button>
      </form>
    </>
  )
}
export default CreateOffice;