import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";

import { KasaEdit, KasaRegisterList } from "./components";


const KasaRegister = (props: any) => {
  const { rest } = props;

  return (
    <Card {...rest}>
      <Grid container>
        <Grid item xs={6}>
            <KasaRegisterList />
        </Grid>
        <Grid item xs={6}>
          <Card >
            <CardContent>傘編集</CardContent>
            <KasaEdit />
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
};

export default KasaRegister;
