// SettlementTable.tsx
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Settlement,
  User,
  cancelSettlement,
  fetchSettlements,
} from "views/CustomerSuccess/cs";
import { timeToStr } from "../../../../../utils/date";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import SuccessDialog from "components/SuccessDialog/SuccessDialog";
import ErrorDialog from "components/ErrorDialog/ErrorDialog";

const useStyles = makeStyles((_theme) => ({
  table: {
    marginTop: "10px",
  },
}));

interface SettlementTableProps {
  user: User;
}

const SettlementTable: React.FC<SettlementTableProps> = (props) => {
  const { user } = props;

  const classes = useStyles();

  const [settlements, setSettlements] = useState<Settlement[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedSettlementId, setSelectedSettlementId] = useState("");

  useEffect(() => {
    setSettlements([]);
    if (user.ikasa_id === undefined) return;

    fetchSettlements(user.ikasa_id)
      .then((data) => {
        setSettlements(data);
      })
      .catch((err) => {
        setErrorMessage("決済情報取得に失敗しました。\nReason: " + err);
        setErrorOpen(true);
      });
    // eslint-disable-next-line
  }, [user]);

  const handleCancelSettlement = async () => {
    try {
      await cancelSettlement(selectedSettlementId);
      setSuccessOpen(true);
      setSettlements([]);
      fetchSettlements(user.ikasa_id)
        .then((data) => {
          setSettlements(data);
        })
        .catch((err) => {
          setErrorMessage("決済情報更新に失敗しました。\nReason: " + err);
          setErrorOpen(true);
        });
    } catch (error) {
      setErrorMessage("請求キャンセルに失敗しました。\nReason: " + error);
      setErrorOpen(true);
    }
  };

  return (
    <>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>支払月</TableCell>
          <TableCell>決済済み日時</TableCell>
          <TableCell>金額</TableCell>
          <TableCell>決済に失敗したことがある</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {settlements.map((settlement, index) => (
          <TableRow key={index}>
            <TableCell>{settlement.month}</TableCell>
            <TableCell>
              {settlement.DeletedAt ? (
                timeToStr(settlement["DeletedAt"])
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                    onClick={() => {
                      setSelectedSettlementId(settlement.settlement_id);
                      setConfirmOpen(true);
                    }}
                  >
                    請求キャンセル
                  </Button>
                )}
            </TableCell>
            <TableCell>{settlement.amount}</TableCell>
            <TableCell>{settlement.pay_failed && <p>有</p>}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      <ConfirmDialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        func={handleCancelSettlement}
        title="本当に請求をキャンセルしますか？"
      />
      <SuccessDialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        title="請求キャンセルに成功しました。"
      />
      <ErrorDialog
        open={errorOpen}
        onClose={() => setErrorOpen(false)}
        title="エラーが発生しました。"
        errorMessage={errorMessage}
      />
    </>
  );
};

export default SettlementTable;
