// cs.ts
import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

export interface User {
  id: number;
  ikasa_id: string;
  plan_type: string;
  sub_plan: string;
  line_id: string;
  google_id: string;
  facebook_id: string;
  odakyu_id: string;
  email: string;
  home_station: string;
  work_station: string;
  orientation_completed_at: string|null;
  terms_agreed_at: string|null;
  previous_user_id: number;
  alias: string;
}

export interface Payment {
  ikasa_id: string;
  pay_type: string;
  stripe_id: string;
  gmo_id: string;
  line_regkey: string;
  office_code: string;
  credit_platform: string;
  card_type: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date|null;
}

export interface Settlement {
  settlement_id: string;
  month: string;
  DeletedAt: string;
  amount: number;
  pay_failed: boolean;
}

export async function fetchUser(
    userId: string, isAlias: boolean): Promise<User|undefined> {
  const aliasQuery = isAlias ? 'alias=true' : '';
  try {
    const response = await axios.get<User>(
        `${API_HOST}/service/v1/setting/user/${userId}?${aliasQuery}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function fetchPayment(ikasaId: string):
    Promise<Payment|undefined> {
  try {
    const response = await axios.get<Payment>(
        `${API_HOST}/service/v1/setting/payment/user/${ikasaId}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

// ユーザーのサブスクプランを解除する
// r.DELETE("/cs/subscription/:ikasa_id", process.CancelSubscription)
export async function cancelSubscription(ikasaId: string): Promise<void> {
  try {
    await axios.delete(`${API_HOST}/service/v1/cs/subscription/${ikasaId}`);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function withDraw(ikasaId: string): Promise<void> {
  try {
    await axios.delete(`${API_HOST}/service/v1/cs/withdraw/${ikasaId}`);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function fetchSettlements(ikasaId: string): Promise<Settlement[]> {
  try {
    const response = await axios.get<Settlement[]>(`${
        API_HOST}/db/v1/payment/settlement/ikasa_id/${ikasaId}?unscoped=true`);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

// 請求キャンセル
export async function cancelSettlement(settlementId: string): Promise<void> {
  try {
    // EP: PUT /db/v1/payment/settlement/cancel/:settlement_id
    await axios.put(`${API_HOST}/db/v1/payment/settlement/cancel/${settlementId}`);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}