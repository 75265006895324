import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { Warning as WarningIcon } from "@material-ui/icons";

interface ConfirmDialogProps {
  onClose: () => void;
  func: () => void;
  open?: boolean;
  title: string;
  message?: string | null;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ onClose, func, open = false, title, message }) => {
  const handleClose = () => {
    onClose();
  };

  const handleExec = () => {
    func();
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="confirm-dialog-title" open={open}>
      <DialogTitle id="confirm-dialog-title">確認</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <WarningIcon style={{ color: '#ff9800', marginRight: '1rem' }} />
          <p>{title}</p>
        </div>
        {message && (
          <p style={{ marginTop: '1rem' }}>{message}</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleExec} color="primary" variant="contained">
          はい
        </Button>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
