// UserInfo.tsx
import { Card, CardContent, Box } from "@material-ui/core";
import React from "react";
import {
  UserDelete,
  UserDetail,
  UserToolBar,
  UserSubscCancelButton,
} from "./components";
import { User, Payment } from "../../cs";

interface UserInfoProps {
  user: User;
  payment: Payment;
  getUserDetails: (userId: string, isAlias?: boolean) => Promise<void>;
  className: string;
  [key: string]: any;
}

const UserInfo: React.FC<UserInfoProps> = ({
  user,
  payment,
  getUserDetails,
  className,
  ...rest
}) => {
  return (
    <Card className={className} {...rest}>
      <CardContent>ユーザー取得</CardContent>
      <UserToolBar getUserDetails={getUserDetails} />
      <UserDetail user={user} payment={payment} />
      <Box display="flex" justifyContent="flex-start" mt={2}>
        <UserDelete ikasaId={user.ikasa_id} />
        <Box ml={2}>
        <UserSubscCancelButton
          ikasaId={user.ikasa_id}
          planType={user.plan_type}
          getUserDetails={getUserDetails}
        />
      </Box>
      </Box>
    </Card>
  );
};

export default UserInfo;