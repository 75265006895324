import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { timeToStr } from "../../../../../utils/date";
import { ConfirmDialog } from "components";

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

const IS_SUCCESS_COLOR = "#757575";

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: "10px"
  },
  success: {
    backgroundColor: IS_SUCCESS_COLOR
  },
  dialog: {
    minWidth: "500px",
    padding: "10px"
  },
  input: {
    minWidth: "450px",
    margin: "10px"
  },
  isEffective: {
    backgroundColor: "#ff8a65"
  },
  isNotEffective: {
    backgroundColor: "#e0e0e0"
  }
}));

const TransactionTable = props => {
  const { user, planType } = props;

  const [tokens, setTokens] = useState([]);
  const [eachTokens, setEachTokens] = useState([]);
  const [opens, setOpens] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    fetchTokens();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user.ikasa_id === undefined) return;

    setOpensList(tokens[planType].length);
    setEachTokens(tokens[planType]);
    // eslint-disable-next-line
  }, [tokens]);

  const fetchTokens = () => {
    if (user.ikasa_id === undefined) return;

    axios
      .get(`${API_HOST}/service/v1/cs/tokens/${user["ikasa_id"]}`)
      .then(res => {
        setTokens(res.data);
        setOpensList(res.data[planType].length);
        setEachTokens(res.data[planType]);
      })
      .catch(err => {
        alert(err.response.data);
      });
  };

  const setUpdatedToken = index => {
    return token => {
      const cpTokens = eachTokens.slice();

      cpTokens[index] = token;
      setEachTokens(cpTokens);
    };
  };

  const setOpensList = len => {
    const opensList = [];
    for (let i = 0; i < len; i++) {
      opensList.push(false);
    }
    setOpens(opensList);
  };

  const switchOpen = (index, isOpen) => {
    const cpOpens = opens.slice();
    cpOpens[index] = isOpen;
    setOpens(cpOpens);
  };

  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>トークンID</TableCell>
              <TableCell>料金</TableCell>
              <TableCell>クーポン</TableCell>
              <TableCell>作成日時</TableCell>
              <TableCell>最終更新日</TableCell>
              <TableCell>失効日時</TableCell>
              <TableCell>ユーザーID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eachTokens.map((token, index) => (
              <React.Fragment key={token["charge_id"]}>
                <TableRow
                  hover={true}
                  role="checkbox"
                  tabIndex={-1}
                  onClick={() => switchOpen(index, true)}
                  className={
                    token["DeletedAt"]
                      ? classes.isNotEffective
                      : classes.isEffective
                  }
                >
                  <TableCell align="left">{token["charge_id"]}</TableCell>
                  <TableCell align="left">{token["price"]}</TableCell>
                  <TableCell align="left">{token["coupon"]}</TableCell>
                  <TableCell align="left">
                    {timeToStr(token["CreatedAt"])}
                  </TableCell>
                  <TableCell align="left">
                    {timeToStr(token["UpdatedAt"])}
                  </TableCell>
                  <TableCell align="left">
                    {timeToStr(token["DeletedAt"])}
                  </TableCell>
                  <TableCell align="left">{token["ikasa_id"]}</TableCell>
                </TableRow>
                <EditDialog
                  token={token}
                  reloadFunc={fetchTokens}
                  planType={planType}
                  setUpdatedToken={setUpdatedToken(index)}
                  onClose={() => switchOpen(index, false)}
                  open={opens[index]}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TransactionTable;

const EditDialog = props => {
  const { token, reloadFunc, planType, setUpdatedToken, onClose, open } = props;

  const [udpateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [cpToken, setCpToken] = useState(token);

  const classes = useStyles();

  const handleUpdate = () => {
    axios
      .put(
        `${API_HOST}/service/v1/cs/tokens/${planType}/${cpToken["charge_id"]}`,
        {
          ikasa_id: cpToken["ikasa_id"],
          charge_id: cpToken["charge_id"],
          price: Number(cpToken["price"]),
          coupon: cpToken["coupon"]
        }
      )
      .then(res => {
        setUpdatedToken(cpToken);
        alert("更新しました。");
        reloadFunc();
        onClose();
      })
      .catch(err => {
        alert(`更新に失敗しました。\n${err.response.data['message']}`);
      });
  };

  const handleDelete = () => {
    axios
      .delete(
        `${API_HOST}/service/v1/cs/tokens/${planType}/${cpToken["charge_id"]}`
      )
      .then(res => {
        setUpdatedToken(cpToken);
        alert("非有効化しました。");
        reloadFunc();
        onClose();
      })
      .catch(err => {
        alert(`非有効化に失敗しました。\n${err.response.data['message']}`);
      });
  };

  const handleChangeValue = (e, target) => {
    const cpCpToken = Object.assign({}, cpToken);
    cpCpToken[target] = e.target.value;
    setCpToken(cpCpToken);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open === undefined ? false : open}
      className={classes.dialog}
    >
      <DialogTitle>トークン更新</DialogTitle>
      <FormControl className={classes.dialog}>
        <TextField
          className={classes.input}
          type="text"
          id="charge_id"
          label="トークンID"
          variant="outlined"
          value={cpToken["charge_id"]}
          onChange={e => handleChangeValue(e, "charge_id")}
        />
        <TextField
          className={classes.input}
          type="text"
          id="ikasa_id"
          label="ユーザーID"
          variant="outlined"
          value={cpToken["ikasa_id"]}
          onChange={e => handleChangeValue(e, "ikasa_id")}
        />
        <TextField
          className={classes.input}
          type="number"
          id="price"
          label="値段"
          variant="outlined"
          value={cpToken["price"]}
          onChange={e => handleChangeValue(e, "price")}
        />
        <TextField
          className={classes.input}
          type="text"
          id="coupon"
          label="クーポン"
          variant="outlined"
          value={cpToken["coupon"]}
          onChange={e => handleChangeValue(e, "coupon")}
        />
      </FormControl>
      <Button onClick={() => setDeleteOpen(true)}>トークンを非有効化</Button>
      <Button onClick={() => setUpdateOpen(true)}>更新</Button>
      <Button onClick={onClose}>キャンセル</Button>
      <ConfirmDialog
        onClose={() => setDeleteOpen(false)}
        open={deleteOpen}
        title="このトークンを非有効化しますか？"
        func={handleDelete}
      />
      <ConfirmDialog
        onClose={() => setUpdateOpen(false)}
        open={udpateOpen}
        title="更新しますか？"
        func={handleUpdate}
      />
    </Dialog>
  );
};
