import React, { useEffect, useState } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import axios from "axios";
import { refineSpots } from "utils/refine";

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

type TProps = {
  scd: String,
  setScd: Function
}

interface ISpot {
  scd: number,
  spot_name: string
}

const TransactonToolbar = ({ scd, setScd }: TProps) => {
  const [spots, setSpots] = useState<Array<ISpot>>([]);
  const [allSpots, setAllSpots] = useState<Array<ISpot>>([]);

  useEffect(() => {
    axios
      .get(`${API_HOST}/service/v1/maintenance/spots`)
      .then(res => {
        setSpots(res.data['spots']);
        setAllSpots(res.data['spots']);
      })
      .catch(err => {
        alert("spots get error");
      });
  }, []);

  const handleChangeScd = (e: any) => {
    setScd(e.target.value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const refinedSpots = refineSpots(allSpots, e.target.value)
    setSpots(refinedSpots)
    if (refinedSpots.length > 0) { setScd(refinedSpots[0].scd) }
  }

  return (
    <>
      <TextField
        label="検索"
        margin="dense"
        variant="outlined"
        onChange={handleSearch}
        fullWidth
      />

      <TextField
        select
        label="返却スポット"
        margin="dense"
        name="scd"
        value={scd}
        fullWidth
        // eslint-disable-next-line react/jsx-sort-props
        variant="outlined"
        onChange={handleChangeScd}
      >
        {spots.map((spot:any, index) => (
          <MenuItem key={index} value={spot.scd}>
            {`${spot.scd}. ${spot.spot_name}`}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default TransactonToolbar;
