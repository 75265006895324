import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { CHARGE, SUBSC } from "../../../../../constants/plan";

const PLAN_LIST = [
  { value: CHARGE, name: "通常" },
  { value: SUBSC, name: "サブスク" }
];

const TokensToolbar = props => {
  const { setPlanType } = props;

  return (
    <Select defaultValue={CHARGE} onChange={e => setPlanType(e.target.value)}>
      {PLAN_LIST.map((plan, index) => (
        <MenuItem key={index} value={plan.value}>
          {plan.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TokensToolbar;
