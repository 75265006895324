import {
  Card,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React from "react";
import theme from "theme";

const useStyles = makeStyles({
  formControl: {
    marginBottom: "20px",
  },
  textField: {
    marginBottom: "20px",
  },
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
});

type PushContentsProps = {
  // タップして開くページのヘッダー画像のURL
  headerImageUrl: string;
  // タップして開くページのタイトル
  pageTitle: string;
  // タップして開くページのサブタイトル
  pageSubtitle: string;
  // ページの本文(HTML)
  pageBody: string;
  // ボタンの文章
  buttonText: string;
  // ボタンのリンク先タイプ（アプリ内ページ or URL）
  buttonType: string;
  // ボタンのリンク先URL
  buttonLink: string;
  // タップして開くページのヘッダー画像のURL変更時のコールバック関数
  onHeaderImageUrlChange: (headerImageUrl: string) => void;
  // タップして開くページのタイトル変更時のコールバック関数
  onPageTitleChange: (pageTitle: string) => void;
  // タップして開くページのサブタイトル変更時のコールバック関数
  onPageSubtitleChange: (pageSubtitle: string) => void;
  // ページの本文(HTML)変更時のコールバック関数
  onPageBodyChange: (pageBody: string) => void;
  // ボタンの文章変更時のコールバック関数
  onButtonTextChange: (buttonText: string) => void;
  // ボタンのリンク先タイプ変更時のコールバック関数
  onButtonTypeChange: (buttonType: string) => void;
  // ボタンのリンク先URL変更時のコールバック関数
  onButtonLinkChange: (buttonLink: string) => void;
};

export default function PushContents(props: PushContentsProps) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <h2>Push通知をタップして開くページの設定（省略可）</h2>
      <TextField
        className={classes.textField}
        label="ページのヘッダー画像のURL"
        value={props.headerImageUrl}
        onChange={(e) => props.onHeaderImageUrlChange(e.target.value)}
        fullWidth
      />
      <TextField
        className={classes.textField}
        label="ページのタイトル"
        value={props.pageTitle}
        onChange={(e) => props.onPageTitleChange(e.target.value)}
        fullWidth
      />
      <TextField
        className={classes.textField}
        label="ページのサブタイトル"
        value={props.pageSubtitle}
        onChange={(e) => props.onPageSubtitleChange(e.target.value)}
        fullWidth
      />
      <FormControl className={classes.textField} fullWidth>
        <InputLabel id="button-type-label">
          ボタンのリンク先タイプ
        </InputLabel>
        <Select
          labelId="button-type-label"
          value={props.buttonType}
          onChange={(e) => props.onButtonTypeChange(e.target.value as string)}
        >
          <MenuItem value="route">アプリ内</MenuItem>
          <MenuItem value="link">外部サイトURL</MenuItem>
        </Select>
      </FormControl>
      <TextField
        className={classes.textField}
        label="ボタンの文章"
        value={props.buttonText}
        onChange={(e) => props.onButtonTextChange(e.target.value)}
        fullWidth
      />
      <TextField
        className={classes.textField}
        label={props.buttonType === 'route' ? 'ボタンのリンク先ルート(ホーム画面なら/home)' : 'ボタンのリンク先URL'}
        value={props.buttonLink}
        placeholder=""
        onChange={(e) => props.onButtonLinkChange(e.target.value)}
        fullWidth
      />
      <TextField
        className={classes.textField}
        label="ページの本文(HTML)"
        value={props.pageBody}
        onChange={(e) => props.onPageBodyChange(e.target.value)}
        fullWidth
        multiline
        placeholder="ページの本文をHTML形式で入力してください。"
        rows={10}
        rowsMax={100}
        variant="outlined"
      />
    </Card>
  );
}
