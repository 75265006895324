import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core'
import axios from "axios";
import { timeToStr } from "../../../../../utils/date";

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: "10px"
  },
}));

const RentalHistoryTable = props => {
  const { user, month } = props;

  const classes = useStyles();

  const [rentalHistory, setRentalHisotry] = useState([]);

  useEffect(() => {
    if ( user.ikasa_id === undefined ) return

    axios
      .get(`${API_HOST}/service/v1/mypage/history/${user.ikasa_id}`)
      .then(res => {
        
        setRentalHisotry(res.data);
      })
      .catch(err => {
        alert("レンタル履歴取得に失敗しました。");
      });
    // eslint-disable-next-line
  }, [user]);

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell >傘ID</TableCell>
          <TableCell >レンタルスポット</TableCell>
          <TableCell >レンタル日時</TableCell>
          <TableCell >返却スポット</TableCell>
          <TableCell >返却日時</TableCell>
          <TableCell >計</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rentalHistory.filter(history => history.month === month).map(hisotry => (
            hisotry.data.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell>{transaction.id}</TableCell>
                <TableCell>{transaction.st_spot_name}</TableCell>
                <TableCell>{timeToStr(transaction.st_time)}</TableCell>
                <TableCell>{transaction.en_spot_name}</TableCell>
                <TableCell>{timeToStr(transaction.en_time)}</TableCell>
                <TableCell>{transaction.total_time}</TableCell>
              </TableRow>
            )
          )))
        }
      </TableBody>
    </Table>
  );
};

export default RentalHistoryTable;
