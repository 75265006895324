import { AmplifySignOut } from '@aws-amplify/ui-react';
import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const LOGO_IMG = 'https://i-kasa.com/wp-content/uploads/2020/02/ikasa-rogo.png'

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  bar: {
    backgroundColor: "#e3f2fd",
  },
  logo: {
    width: 50
  },
  flexGrow: {
    flexGrow: 1
  },
}));


const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, classes.bar, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar >
        <RouterLink to="/">
          <img
            alt="Logo"
            className={classes.logo}
            src={LOGO_IMG}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <AmplifySignOut buttonText="ログアウト" />
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
