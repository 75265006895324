import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCuJcQR6wHfzEKmtErx0etqjeBFg6sattQ",
  authDomain: "ikasa-1563170568813.firebaseapp.com",
  databaseURL: "https://ikasa-1563170568813.firebaseio.com",
  projectId: "ikasa-1563170568813",
  storageBucket: "ikasa-1563170568813.appspot.com",
  messagingSenderId: "549092460841",
  appId: "1:549092460841:web:5ea71c69d7ddf285cc0f38",
  measurementId: "G-9Z6777GSL4"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const storage = firebase.storage();
export default firebase;
