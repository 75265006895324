import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { timeToStr } from "../../../../../utils/date";
import { User } from "views/CustomerSuccess/cs";
import ErrorDialog from "../../../../../components/ErrorDialog/ErrorDialog";

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: "10px",
  },
}));

type Coupon = {
  type: string;
  code: string;
  DeletedAt: string;
  CreatedAt: string;
};

interface CouponTableProps {
  user: User;
}
export default function CouponTable(props: CouponTableProps) {
  const { user } = props;

  const classes = useStyles();

  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setCoupons([]);
    if (user.ikasa_id === undefined) return;

    axios
      .get(`${API_HOST}/db/v1/coupon/master/ikasa_id/${user.ikasa_id}?unscoped=true`)
      .then((res) => {
        setCoupons(res.data);
      })
      .catch((err) => {
        if (err.response.data.message === "Not found") {
          setCoupons([]);
        } else {
        setErrorMessage("クーポン情報取得に失敗しました。 " + err.response.data.message);
        setIsErrorDialogOpen(true);
        }
      });
  }, [user]);

  const handleErrorDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  return (
    <>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>クーポン名</TableCell>
          <TableCell>クーポンコード</TableCell>
          <TableCell>クーポン適用日時</TableCell>
          <TableCell>クーポン使用日時</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
          {coupons.length > 0 ? (
            coupons.map((coupon, index) => (
          <TableRow key={index}>
            <TableCell>{coupon.type}</TableCell>
            <TableCell>{coupon.code}</TableCell>
            <TableCell>{timeToStr(coupon.CreatedAt)}</TableCell>
            <TableCell>{timeToStr(coupon.DeletedAt)}</TableCell>
          </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography align="center">レコードが存在しません。</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ErrorDialog
        open={isErrorDialogOpen}
        onClose={handleErrorDialogClose}
        title="クーポン情報取得エラー"
        errorMessage={errorMessage}
      />
    </>
  );
}
