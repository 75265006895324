import React, { useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { KasaLogToolbar, KasaLogTable } from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),

  }
}));

const KasaLog = () => {
  const classes = useStyles();

  const [log, setLog] = useState([]);
  const [ksid, setKsid] = useState(0);

  return (
    <Card className={classes.root}>
      <CardContent>傘移動ログ</CardContent>
      <KasaLogToolbar setLog={setLog} ksid={ksid} setKsid={setKsid}/>
      <KasaLogTable log={log}/>
    </Card>
  );
};

export default KasaLog;
