import React from "react";
import { Card, CardContent } from "@material-ui/core";

import { AmoutIDetail } from "./components";

const Return = props => {
  const { user, ...rest } = props;

  // const [month, setMonth] = useState(0);

  return (
    <Card {...rest}>
      <CardContent>当月料金</CardContent>
      {/* <AmountToolbar setMonth={setMonth} /> */}
      <AmoutIDetail user={user}/>
    </Card>
  );
};

export default Return;
