import React from "react";
import {
  Button,
  TextField,
} from "@material-ui/core";
import axios from "axios";

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

const KasaLogToolbar = (props: any) => {
  const { ksid, setKsid, setLog } = props;

  const getLog = () => {
    axios
      .get(`${API_HOST}/db/v1/transaction/ksid/${ksid}?unscoped=true`)
      .then(res => {
        setLog(res.data);
      })
      .catch(err => {
        alert("ログ取得に失敗しました。");
      });
  }

  return (
    <div>
      <TextField
        label="傘ID"
        value={ksid}
        onChange={(e: any): void => setKsid(e.target.value)}
      />

      <Button
        onClick={getLog}
        variant="outlined"
        color="primary"
      >
        取得
      </Button>
    </div>
  );
};

export default KasaLogToolbar;
