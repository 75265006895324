// UserSubscCancelButton.tsx
import React, { useState } from "react";
import { ConfirmDialog, ErrorDialog, SuccessDialog } from '../../../../../components';
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { cancelSubscription } from '../../../cs';
import axios from 'axios';

const useStyles = makeStyles(_theme => ({
  button: {
    marginTop: "20px",
    height: "40px",
    width: "120px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
  },
}));

interface UserSubscCancelButtonProps {
  ikasaId: string;
  planType: string;
  getUserDetails: (userId: string, isAlias?: boolean) => Promise<void>;
}

const UserSubscCancelButton: React.FC<UserSubscCancelButtonProps> = ({ ikasaId, planType, getUserDetails }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription(ikasaId);
      setOpen(false);
      setSuccessOpen(true);
      await getUserDetails(ikasaId);
    } catch (error) {
      setOpen(false);
      setErrorOpen(true);
      if (axios.isAxiosError(error)) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("An unknown error occurred.");
      }
    }
  };

  if (planType !== "subsc") {
    return null;
  }

  return (
    <>
      <div className={classes.buttonContainer}>
        <Button
          onClick={() => setOpen(true)}
          className={classes.button}
        variant="outlined"
        color="secondary"
      >
        サブスク解除
      </Button>
      </div>
      <ConfirmDialog
        onClose={() => setOpen(false)}
        func={handleCancelSubscription}
        open={open}
        title="このユーザーのサブスクリプションを解除しますか？"
      />
      <SuccessDialog
        onClose={() => setSuccessOpen(false)}
        open={successOpen}
        title="サブスクリプションが解除されました。"
      />
      <ErrorDialog
        onClose={() => setErrorOpen(false)}
        open={errorOpen}
        title="エラー"
        errorMessage={errorMessage}
      />
    </>
  );
};

export default UserSubscCancelButton;
