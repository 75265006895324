export const refineSpots = (allSpots, searchWord) => {
  const refinedSpots = allSpots.filter(spot => spot.spot_name.includes(searchWord) || spot.scd === Number(searchWord))

  if (searchWord.length === 0) {
    return allSpots
  }
  if (refinedSpots.length === 0) {
    return []
  }

  return refinedSpots
}
