import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core'
import { timeToStr } from '../../../utils/date'


const useStyles = makeStyles(theme => ({
  table: {
    marginTop: "10px"
  },
}));

const KasaLogTable = (props: any) => {
  const { log } = props;

  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell >傘ID</TableCell>
          <TableCell >ユーザーID / ログタイプ</TableCell>
          <TableCell >レンタル開始スポット</TableCell>
          <TableCell >レンタル開始時間</TableCell>
          <TableCell >レンタル終了スポット</TableCell>
          <TableCell >レンタル終了時間</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          log.map((transaction: any) => (
            <TableRow key={transaction.ID}>
              <TableCell>{transaction['ksid']}</TableCell>
              <TableCell>{transaction['ikasa_id']}</TableCell>
              <TableCell>{transaction['rented_scd']}</TableCell>
              <TableCell>{timeToStr(transaction['rented_at'])}</TableCell>
              <TableCell>{transaction['returned_scd']}</TableCell>
              <TableCell>{timeToStr(transaction['DeletedAt'])}</TableCell>
            </TableRow>
          )
          )
        }
      </TableBody>
    </Table>
  );
};

export default KasaLogTable;
