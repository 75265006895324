import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    title: 'CS',
    description:
      'CS全般',
    path: '/cs',
  },
  {
    id: uuid(),
    title: 'スポット情報',
    description:
      '傘のスポットの情報を知ることができます',
    path: '/spot/register',
  },
  {
    id: uuid(),
    title: '傘移動ログ',
    description: '傘の移動したログを見れます。',
    path: '/maintenance/umbrella/log',
  },
  {
    id: uuid(),
    title: '傘移動',
    description:
      '傘を移動させることができます。レンタル中・どこにもストックされていない傘については、移動できません。',
    path: '/maintenance/umbrellas/move',
  },
  {
    id: uuid(),
    title: 'ストック一覧',
    description:
      'スポットごとのストックされている傘IDを閲覧することができます',
    path: '/maintenance/umbrellas',
  },
  {
    id: uuid(),
    title: 'オフィス登録',
    description:
      'オフィスを新規登録出来ます。',
    path: '/office',
  },

  {
    id: uuid(),
    title: '傘登録',
    description:
      '傘の登録を行えます。',
    path: '/maintenance/umbrella/register',
  },

  {
    id: uuid(),
    title: 'プッシュ通知',
    description:
      'プッシュ通知を行えます。',
    path: '/pushNotification',
  }

];
