import axios from "axios";

export type PushNotificationRequest = {
  environment: string;
  title: string;
  body: string;
  priority: string;
  id: string;
  page: {
    type: string;
    image: string;
    title: string;
    subtitle: string;
    content: string;
    button: {
      text: string;
      action: {
        link: string;
        type: string;
      };
    };
  };
  // 送り先、ikasa_id or topics
  // https://firebase.google.com/docs/cloud-messaging/android/topic-messaging?hl=ja
  // 今回の実装では個別に送るので、toは必要ない
  // to: string;
  // 送り先、ikasa_idのリスト。 to に値がある場合は、 to を優先。（上限：1000件）
  ikasa_id_list: String[];
};

export type PushNotificationResponse = {
  fcm: Record<string, string>;
  push_count: number;
  exist_count: number;
  not_exist_count: number;
};

export async function sendChunkPushNotifiacation(
  request: PushNotificationRequest
): Promise<PushNotificationResponse> {
  // リクエストのikasa_id_listが１０００件を超える場合はリクエスト自体を分割して送信する
  if (request.ikasa_id_list.length > 1000) {
    alert(
      "送信対象のアイカサIDが1000件を超えていますので、完了するまで時間がかかります"
    );

    const splitRequestList = [];
    let splitRequest: PushNotificationRequest = {
      environment: request.environment,
      title: request.title,
      body: request.body,
      priority: request.priority,
      id: request.id,
      page: request.page,
      ikasa_id_list: [],
    };

    for (let i = 0; i < request.ikasa_id_list.length; i++) {
      splitRequest.ikasa_id_list.push(request.ikasa_id_list[i]);

      if (splitRequest.ikasa_id_list.length === 1000) {
        splitRequestList.push(splitRequest);

        splitRequest = {
          environment: request.environment,
          title: request.title,
          body: request.body,
          priority: request.priority,
          id: request.id,
          page: request.page,
          ikasa_id_list: [],
        };
      }
    }

    if (splitRequest.ikasa_id_list.length > 0) {
      splitRequestList.push(splitRequest);
    }

    let res: PushNotificationResponse = {
      fcm: {},
      push_count: 0,
      exist_count: 0,
      not_exist_count: 0,
    };

    console.log("splitRequestList length: ", splitRequestList.length);

    for (let i = 0; i < splitRequestList.length; i++) {
      let resSplit = await pushNotifiacation_api(splitRequestList[i]);
      res.push_count += resSplit.push_count;
      res.exist_count += resSplit.exist_count;
      res.not_exist_count += resSplit.not_exist_count;
    }

    return res;
  } else {
    return await pushNotifiacation_api(request);
  }
}

export async function pushNotifiacation_api(
  request: PushNotificationRequest
): Promise<PushNotificationResponse> {
  const reqURLDev =
    "https://api-dev.i-kasa.com/service/v1/mypage/push_notifications";
  const reqURLProd =
    "https://api.i-kasa.com/service/v1/mypage/push_notifications";
  let api_host = "";
  if (request.environment === "dev") {
    api_host = reqURLDev;
  } else {
    api_host = reqURLProd;
  }

  console.log("api_host: ", api_host);

  let res = await axios
    .post<PushNotificationResponse>(`${api_host}`, {
      title: request.title,
      body: request.body,
      priority: request.priority,
      id: request.id,
      // request.page.imageとrequest.page.titleが空ならpageは含まない
      type: request.page.title === "" ? null : "page",
      page:
        request.page.title === ""
          ? null
          : {
              image: request.page.image,
              title: request.page.title,
              subtitle: request.page.subtitle,
              content: request.page.content,
              button: {
                text: request.page.button.text,
                action: {
                  link: request.page.button.action.link,
                  type: request.page.button.action.type,
                },
              },
            },
      ikasa_id_list: request.ikasa_id_list,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (axios.isAxiosError(err)) {
        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "通知送信に失敗しました。";
        alert(errorMessage);
      } else {
        alert("ネットワークエラーが発生しました。 \n" + err);
      }
      throw err;
    });
  return res;
}

