import {
  Button,
  Card,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import theme from "theme";

const useStyles = makeStyles({
  formControl: {
    marginBottom: "30px",
  },
  textField: {
    marginBottom: "30px",
  },
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  csvInput: {
    display: "none",
  },
  margin_vertical: {
    margin: "30px 0",
  },
});

type TargetSettingsProps = {
  // プッシュ通知環境
  environment: string;
  // プッシュ通知の優先度
  priority: string;
  // 通知対象アイカサIDのリスト
  targetIds: String[];
  // 環境変更時のコールバック関数
  onEnvironmentChange: (environment: string) => void;
  // 優先度変更時のコールバック関数
  onPriorityChange: (priority: string) => void;
  // 通知対象アイカサIDのリスト変更時のコールバック関数
  onTargetIdsChange: (targetIds: String[]) => void;
};

export default function TargetSettings(props: TargetSettingsProps) {
  const classes = useStyles();
  const [inputMethod, setInputMethod] = useState("csv");
  const [singleId, setSingleId] = useState("");

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.onTargetIdsChange([]);
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvData = event.target?.result as string;
        const lines = csvData.split("\n");
        const ids = lines.map((line) => line.trim());
        props.onTargetIdsChange(ids);
      };
      reader.readAsText(file);
    }
  };

  const handleSingleIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newSingleId = event.target.value;
    setSingleId(newSingleId);
    props.onTargetIdsChange([newSingleId]);
  };

  return (
    <Card className={classes.root}>
      <Typography variant="h5" gutterBottom>
        ターゲット設定
      </Typography>
      <RadioGroup
        value={inputMethod}
        onChange={(e) => setInputMethod(e.target.value)}
      >
        <FormControlLabel
          value="csv"
          control={<Radio />}
          label="CSVファイルで指定"
        />
        <FormControlLabel
          value="single"
          control={<Radio />}
          label="単体で指定"
        />
      </RadioGroup>
      {inputMethod === "csv" && (
        <>
          <Typography variant="h6" gutterBottom>
            通知対象をCSVで指定
          </Typography>
          <input
            accept=".csv"
            className={classes.csvInput}
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              CSVファイルを選択
            </Button>
          </label>
          <Typography
            variant="body1"
            className={classes.margin_vertical}
            color={props.targetIds.length > 0 ? "textPrimary" : "textSecondary"}
          >
            {props.targetIds.length > 0
              ? `${props.targetIds.length}件のユーザーIDが読み込まれました`
              : "ユーザーIDが読み込まれていません"}
          </Typography>
        </>
      )}
      {inputMethod === "single" && (
        <>
          <Typography variant="h6" gutterBottom>
            通知対象を単体で指定
          </Typography>
          <TextField
            style={{ marginBottom: "20px" }}
            label="アイカサID"
            value={singleId}
            onChange={handleSingleIdChange}
            fullWidth
          />
        </>
      )}
      <Typography variant="h6" gutterBottom>
        通知対象環境設定
      </Typography>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel>環境</InputLabel>
        <Select
          value={props.environment}
          onChange={(e) => {
            props.onEnvironmentChange(e.target.value as string);
          }}
        >
          <MenuItem value="dev">開発環境</MenuItem>
          <MenuItem value="prod">プロダクション環境</MenuItem>
        </Select>
      </FormControl>

      <Typography variant="h6" gutterBottom>
        通知の優先度設定
      </Typography>
      {/* 優先度設定の注意書き */}
      <Typography
        variant="body2"
        color={props.priority === "high" ? "error" : "textSecondary"}
        style={{
          fontWeight: props.priority === "high" ? "bold" : "normal",
          backgroundColor:
            props.priority === "high" ? "#ffebee" : "transparent",
          padding: props.priority === "high" ? "8px" : "0",
          borderRadius: props.priority === "high" ? "4px" : "0",
        }}
      >
        ※優先度を「高」に設定すると、アプリの個別のお知らせに表示されます。
      </Typography>
      <br />
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel>優先度</InputLabel>
        <Select
          value={props.priority}
          onChange={(e) => {
            props.onPriorityChange(e.target.value as string);
          }}
        >
          <MenuItem value="high">高</MenuItem>
          <MenuItem value="normal">通常</MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
}
