import React, { useState } from "react";
import { Card, CardContent } from "@material-ui/core";

import { RentalHistoryToolbar, RentalHistoryTable } from "./components";

const Return = props => {
  const { user, ...rest } = props;

  const [month, setMonth] = useState(0);

  return (
    <Card {...rest}>
      <CardContent>レンタル履歴</CardContent>
      <RentalHistoryToolbar setMonth={setMonth} />
      <RentalHistoryTable user={user} month={month}/>
    </Card>
  );
};

export default Return;
