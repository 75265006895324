import React, { useState } from "react";
import {
  Card,
  CardContent,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Paper,
  makeStyles
} from "@material-ui/core";

import { STATUS_LIST, IS_RENTERING_CODE} from '../../../../constants/status'

const UMBLRELLA_LIMIT = 45;

const normalizes2Digit = int => (("0" + int).slice(-2));
const TimeToStr = (date) => {
  if (date) {
    const dateObj = new Date( Date.parse(date) )

    const month = normalizes2Digit(dateObj.getMonth() + 1);
    const day = normalizes2Digit(dateObj.getDate());
    const hours = normalizes2Digit(dateObj.getHours());
    const minutes = normalizes2Digit(dateObj.getMinutes());
    return `${month}/${day} ${hours}:${minutes}`
  }
}


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.5)
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  title: {
    fontSize: 14
  },
  input: {
    padding: 4,
    paddingBottom: theme.spacing(1.5)
  },
  table: {
    minWidth: 650
  }
}));

const MoveUmbrellaList = props => {
  const {
    className,
    stockInfo,
    umbrellas,
    setUmbrellas,
    successes,
    failures,
    ...rest
  } = props;

  const classes = useStyles();

  const [inputUmbrella, setInputUmbrella] = useState("");

  const generateErrorMessage = failure => {
    const statusObj = STATUS_LIST.filter(
      val => val.status === failure.status
    )[0];
    if (statusObj.status === IS_RENTERING_CODE) {
      return `ユーザーID：${failure.datail_data.ikasa_id}が
      ${TimeToStr(failure.datail_data.rented_at)}から${statusObj.message}`;
    }

    return statusObj.message;
  };

  const isUniqueUmbrella = targetUmbrella => {
    for (const umbrella of umbrellas) {
      if (umbrella === targetUmbrella) return false;
    }
    return true;
  };

  const handleKeyDown = e => {
    if (e.keyCode !== 13 || e.target.value === "") return;

    const parsed = parseToUmbrellaList(e.target.value);
    if (!validateUmbrellaList(parsed)) return;

    const newUmbrellas = [...parsed, ...umbrellas];
    newUmbrellas.sort((i, j) => i - j);
    setUmbrellas(newUmbrellas);
    setInputUmbrella("");
  };

  const parseToUmbrellaList = (umbrellaStr) => {
    const trimmed = umbrellaStr.trim();
    const splitted = trimmed.split(/ +|　+|\n+|,+/g);
    return splitted.map((umbrella) => parseInt(umbrella));
  }

  const validateUmbrellaList = (list) => {
    for (let umbrella of list) {
      if (!validateUmbrella(umbrella)) return false;
    }
    return true
  }

  const validateUmbrella = (umbrella) => {
    if (!Number.isInteger(umbrella)) {
      alert(`傘ID${umbrella}：数字のみ入力可能です。`);
      return false;
    }

    if (umbrella < 0) {
      alert(`傘ID${umbrella}：負の値は入力できません`);
      return false;
    }

    if (!isUniqueUmbrella(umbrella)) {
      alert(`傘ID${umbrella}：傘が重複しています。`);
      return false;
    }

    if (umbrellas.length > UMBLRELLA_LIMIT) {
      alert(`${UMBLRELLA_LIMIT}本までしか入力できません`);
      return false;
    }

    return true;
  }

  const handleDelete = index => () => {
    setUmbrellas(umbrellas =>
      umbrellas.filter(umbrella => umbrella !== umbrellas[index])
    );
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={2}>
          <Card {...rest}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                合計： {umbrellas.length}　本
              </Typography>
            </CardContent>
            <TextField　
              type="string"
              variant="outlined"
              label="傘IDを入力してください"
              value={inputUmbrella}
              onChange={e => setInputUmbrella(e.target.value)}
              onKeyDown={handleKeyDown}
              fullWidth
            ></TextField>
            <Paper className={classes.root}>
              {umbrellas.map((umbrella, index) => {
                return (
                  <Chip
                    key={index}
                    label={umbrella}
                    onDelete={handleDelete(index)}
                    className={classes.chip}
                  />
                );
              })}
            </Paper>
          </Card>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Card>
            <CardContent>
              <Typography variant="h3">成功</Typography>
              <span>計： {successes.count}</span>
            </CardContent>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>傘ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {successes.content.map(umbrella => (
                    <TableRow key={umbrella}>
                      <TableCell align="left">{umbrella}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Card>
            <CardContent>
              <Typography variant="h3">失敗</Typography>
              <span>計： {failures.count}</span>
            </CardContent>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>傘ID</TableCell>
                    <TableCell>エラー内容</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {failures.content.map(failure => (
                    <TableRow key={failure.ksid}>
                      <TableCell align="left">{failure.ksid}</TableCell>
                      <TableCell align="left">
                        {generateErrorMessage(failure)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default MoveUmbrellaList;
