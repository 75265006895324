export const SUCCESS_CODE: string = "0000";

export const NOT_STOCK_CODE: string = "2101";
export const NOT_STOCK_BUT_CREATED_CODE: string = "2102";
export const IS_RENTERING_CODE: string = "2201";

export const DATA_CAP_OVER_CODE: string = "3101";

export const UNDEFINED_CODE: string = "4000";
export const BIND_ERROR_CODE : string= "4101";
export const IS_RENTERING_BY_MULTIPLE_CODE: string =  "4201";

export const STATUS_LIST: {status: string; message: string; }[] = [
  { status: SUCCESS_CODE, message: "" },
  { status: NOT_STOCK_CODE, message: "ストックされていません" },
  { status: NOT_STOCK_BUT_CREATED_CODE, message: "ストックされていなかったので、作成しました" },
  { status: IS_RENTERING_CODE, message: "借りています" },
  { status: DATA_CAP_OVER_CODE, message: "件数上限オーバーです" },
  { status: UNDEFINED_CODE, message: "未定義のエラーです" },
  { status: BIND_ERROR_CODE, message: "値が不正です" },
  { status: IS_RENTERING_BY_MULTIPLE_CODE, message: "複数人に借りられています" },
];
