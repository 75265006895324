import { AuthState } from '@aws-amplify/ui-components';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { ThemeProvider } from '@material-ui/styles';
import { Amplify, Auth } from 'aws-amplify';
import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import { Chart } from 'react-chartjs-2';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Router } from 'react-router-dom';
import validate from 'validate.js';
import { SignIn } from 'views';
import validators from './common/validators';
import { cognitoConfig } from './constants/auth';
import { chartjs } from './helpers';
import Routes from './Routes';
import theme from './theme';
import { SignUp } from './views';
import axios from 'axios';


const browserHistory = createBrowserHistory();
const SIGN_UP_PATH = '/sign-up/2MrJy24rEUacQNeez_Df4VZXgmxB4B99kCWQ38Y5Tn6kyJFTS4EWMhjfgXarRFsd3Bs8z3u7MyayNzX';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

Amplify.configure(cognitoConfig);

// リクエストする時に、cognito id tokenをAuthorization headerにセット
axios.interceptors.request.use(
  async config => {

    const authUser = await Auth.currentAuthenticatedUser();
    await authUser.getSession(
      async (err, session) => {
        if (err) {
          console.error('failed to add Authorization header');
          console.error(err);
          return;
        }
        const authToken = await session.getIdToken().getJwtToken();
        config.headers['Authorization'] = `Bearer ${authToken}`;
        console.log('added Authorization header');
      }
    );
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

export default class App extends Component {
  render() {
    const path = window.location.pathname;
    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <AmplifyAuthenticator
            initialAuthState={path === SIGN_UP_PATH ? AuthState.SignUp : AuthState.SignIn}
          >
            <Routes />
            <SignUp />
            <SignIn />
          </AmplifyAuthenticator>
        </Router>
      </ThemeProvider>
    );
  }
}
