import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { SpotList, SpotToolbar } from './components';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const initSpotInfo = {
  scd: '',
  spot_id: '',
  ble_id: '',
  area: '',
  spot_name: '',
  detail_url: '',
  spot_img: '',
  in_out_door: '',
  type: '',
  industry_type: '',
  kasa_max: 0,
  share_start_time: '',
  share_end_time: '',
  start_time: '',
  end_time: '',
  created_at: '',
  updated_at: '',
  position: {
    lat: '',
    lng: '',
  },
  prefecture_id: 0,
  image_urls_list: [],
  social_url: {
    line: '',
    twitter: '',
    instagram: '',
    facebook: '',
  },
  spot_name_detail: '',
  share_time_detail: '',
  time_detail: '',
  holiday: '',
  sub_category: '',
  stand_place: '',
  address: '',
  url: '',
  introduction: '',
  is_hidden: false,
  is_maintenance: false,
}

const List = () => {
  const classes = useStyles();

  const [spotInfo, setSpotInfo] = useState(initSpotInfo);
  const [isCreateAction, setIsCreateAction] = useState(true);

  return (
    <div className={classes.root}>
      <h3>傘スポット一覧</h3>
      <SpotToolbar
        spotInfo={spotInfo}
        setSpotInfo={setSpotInfo}
        initSpotInfo={initSpotInfo}
        isCreateAction={isCreateAction}
        setIsCreateAction={setIsCreateAction}
      />
      <div className={classes.content}>
        <SpotList
          spotInfo={spotInfo}
          setSpotInfo={setSpotInfo}
          isCreateAction={isCreateAction}
        />
      </div>
    </div>
  );
};

export default List;
