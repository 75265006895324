import React from "react";
import CreateOffice from "./compnnents/CreateOffice";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const GeneralOffice = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CreateOffice className={classes.content} ></CreateOffice>
    </div>
  
  )
};
export default GeneralOffice