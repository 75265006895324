import { AmplifySignUp } from '@aws-amplify/ui-react';
import React from 'react';


const SignUp = () => {
  return (
    <AmplifySignUp
      slot="sign-up"
      headerText='アカウントを作成'
      signInText='ログイン'
      haveAccountText='アカウントを持っていますか？'
      submitButtonText='サインアップ'
      formFields={[
        {
          type: "username",
          label: "ユーザー名",
          required: true,
          placeholder: 'ユーザー名を入力してください'
        },
        {
          type: "email",
          label: "メールアドレス",
          required: true,
          placeholder: 'メールアドレスを入力してください'
        },
        {
          type: "password",
          label: "パスワード",
          required: true,
          placeholder: 'パスワードを入力してください'
        }
      ]}
    />
  );
};




export default SignUp;
