import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";

import { MoveUmbrellaToolbar, MoveUmbrellaList } from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const initialSuccess = { count: 0, content: [] };
const initialFailure = { count: 0, content: [] };

const UserList = () => {
  const classes = useStyles();

  const [scd, setScd] = useState(1);
  const [umbrellas, setUmbrellas] = useState([]);
  const [successes, setSuccesses] = useState(initialSuccess);
  const [failures, setFailures] = useState(initialFailure);

  return (
    <div className={classes.root}>
      <h3>傘移動</h3>
      <MoveUmbrellaToolbar
        scd={scd}
        setScd={setScd}
        umbrellas={umbrellas}
        setUmbrellas={setUmbrellas}
        setSuccesses={setSuccesses}
        setFailures={setFailures}
        initialSuccess={initialSuccess}
        initialFailure={initialFailure}
      />
      <div className={classes.content}>
        <MoveUmbrellaList
          setUmbrellas={setUmbrellas}
          umbrellas={umbrellas}
          successes={successes}
          failures={failures}
        />
      </div>
    </div>
  );
};

export default UserList;
