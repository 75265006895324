// Payments.tsx
import React from "react";
import { Card, CardContent } from "@material-ui/core";

import { SettlementTable, TransactionTable } from "./components";
import { User } from "views/CustomerSuccess/cs";


interface PaymentProps {
  user: User;
  [key: string]: any;
}

const Payment: React.FC<PaymentProps> = (props) => {
  const { user, ...rest } = props;

  return (
    <Card {...rest}>
    <CardContent>決済情報</CardContent>
    <CardContent>月ごとの決済情報</CardContent>
      <SettlementTable user={user} />
      <CardContent style={{ marginTop: 50 }}>決済済み一覧</CardContent>
      <TransactionTable user={user} />
    </Card>
  );
};

export default Payment;