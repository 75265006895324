import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import React from "react";
import { Warning as WarningIcon, Info as InfoIcon, Error as ErrorIcon } from "@material-ui/icons";

interface ResultDialogProps {
  dialogType: string;
  open: boolean;
  onClose: () => void;
  message: string;
}

const ResultDialog: React.FC<ResultDialogProps> = ({ open, onClose, message, dialogType }) => {
  let icon: JSX.Element | null = null;
  let title = "";

  switch (dialogType) {
    case "警告":
      icon = <WarningIcon style={{ marginRight: "8px", color: "#ff9800" }} />;
      title = "警告";
      break;
    case "結果":
      icon = <InfoIcon style={{ marginRight: "8px", color: "#2196f3" }} />;
      title = "結果";
      break;
    case "エラー":
      icon = <ErrorIcon style={{ marginRight: "8px", color: "#f44336" }} />;
      title = "エラー";
      break;
    default:
      break;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon}
          <div style={{ marginLeft: "10px" }}>{title}</div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResultDialog;