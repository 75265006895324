import { Button, MenuItem, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  }
}));

const SpotToolbar = props => {
  const {
    className,
    spotInfo,
    setSpotInfo,
    initSpotInfo,
    isCreateAction,
    setIsCreateAction,
    ...rest
  } = props;

  const classes = useStyles();

  const [scd, setScd] = useState(1);
  const [spotsList, setSpotsList] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  // const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  useEffect(() => {
    fetchGetSpots();
  }, []);

  const switchAction = () => {
    setIsCreateAction(!isCreateAction);
    clearSpotInfo();
  }

  const clearSpotInfo = () => {
    setSpotInfo(initSpotInfo);
  }

  const fetchGetSpots = async () => {
    axios
      .get(`${API_HOST}/service/v1/maintenance/spots`)
      .then(res => {
        setSpotsList(res.data['spots']);
      })
      .catch(() => {
        alert("スポット一覧の取得に失敗しました。");
      });
  }

  const getNormalizesFetchSpotInfo = (notYetNormalizesSpot) => {
    let spot = Object.assign({}, notYetNormalizesSpot);

    if (!(spot['position'])) {
      spot['position'] = initSpotInfo['position'];
    }

    if (!(spot['social_url'])) {
      spot['social_url'] = initSpotInfo['social_url'];
    }

    if (!(spot['prefecture_id'])) {
      spot['prefecture_id'] = initSpotInfo['prefecture_id'];
    }

    return spot
  }

  const getNormalizesRegisterSpotInfo = (notYetNormalizesSpot) => {
    let spot = Object.assign({}, notYetNormalizesSpot)

    spot['kasa_max'] = Number(spot['kasa_max']);
    spot['position']['lat'] = Number(spot['position']['lat']);
    spot['position']['lng'] = Number(spot['position']['lng']);

    delete spot['created_at']
    delete spot['updated_at']

    let cpImageUrlsList = spot['image_urls_list'];
    for (const index in spot['image_urls_list']) {
      if (!(spot['image_urls_list'][index])) {
        cpImageUrlsList.splice(index, 1);
      }
    }
    setSpotInfo({ ...spotInfo, image_urls_list: cpImageUrlsList });

    return spot
  }

  const handleValidation = () => {
    setIsFormValid(false);
    if (!(spotInfo['scd'])) {
      alert('スポットコードは必要');
      return;
    }
    if (!(spotInfo['prefecture_id']) || spotInfo['prefecture_id'] <= 0) {
      alert('都道府県は必要');
      return;
    }
    setIsFormValid(true);
  }

  const createSpot = () => {
    handleValidation();
    if (!isFormValid) return;

    const spot = getNormalizesRegisterSpotInfo(spotInfo);

    axios.post(`${API_HOST}/db/v1/spot`, spot)
      .then(() => {
        alert('スポット新規作成が完了しました。')
        setIsCreateAction(false);
        setScd(spot['scd']);
      })
      .catch(() => {
        alert('スポット新規作成に失敗しました。')
      })
  }

  const fetchSpot = e => {
    if (!scd) return;
    axios
      .get(`${API_HOST}/db/v1/spot/scd/${scd}`)
      .then(res => {
        setSpotInfo(getNormalizesFetchSpotInfo(res.data));
      })
      .catch(err => {
        alert("スポットを取得できませんでした");
      });
  };

  const updateSpot = () => {
    handleValidation();
    if (!isFormValid) { return; }

    const spot = getNormalizesRegisterSpotInfo(spotInfo);

    axios.put(`${API_HOST}/db/v1/spot/scd/${spotInfo['scd']}`, spot)
      .then(() => {
        alert(`スポット：${spotInfo['scd']}の更新が完了しました。`)
      })
      .catch(() => {
        alert(`スポット：${spotInfo['scd']}の更新に失敗しました。`)
      })
  }

  const deleteSpot = () => {
    if (!scd) return;

    axios.delete(`${API_HOST}/db/v1/spot/scd/${scd}`)
      .then(() => {
        alert(`スポット：${spotInfo['scd']}を削除しました。`);
        setIsCreateAction(true);
        setSpotInfo(initSpotInfo);
        setScd(1);
        removeSpot(scd)
      })
      .catch(() => {
        alert(`スポット：${spotInfo['scd']}の削除に失敗しました`);
      });
  }

  const removeSpot = (scd) => {
    for (let i = 0; i < spotsList.length; i++) {
      if (spotsList[i]['scd'] === scd) {
        spotsList.splice(i, 1)
      }
    }
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div>
        <span>処理切替：</span>
        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          onClick={switchAction}
        >
          {
            isCreateAction ? '編集モード' : '編集解除'
          }
        </Button>
        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          onClick={clearSpotInfo}
        >
          リセット
        </Button>

      </div>
      <div className={classes.row}>
        {
          isCreateAction ?

            <Button
              className={classes.button}
              variant="outlined"
              color="secondary"
              // onClick={AddNewSpot}
              fullWidth
              onClick={createSpot}
            >
              スポット新規作成
            </Button>

            :
            <>
              <TextField
                select
                label="スポットコード"
                margin="dense"
                name="scd"
                value={scd}
                fullWidth
                // eslint-disable-next-line react/jsx-sort-props
                variant="outlined"
                onChange={e => {
                  clearSpotInfo();
                  setScd(e.target.value);
                }}
              >
                {spotsList.map(spot => (
                  <MenuItem key={spot['scd']} value={spot['scd']}>
                    {`${spot['scd']}. ${spot['spot_name']}`}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={fetchSpot}
              >
                取得
              </Button>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={updateSpot}
              >
                更新
              </Button>
              {/* <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={() => setDeleteConfirmOpen(true)}
              >
                削除
          </Button> */}

              {/* <ConfirmDialog
                onClose={() => setDeleteConfirmOpen(false)}
                func={deleteSpot}
                open={deleteConfirmOpen}
                title="このスポットを削除しますか？"
              /> */}

            </>
        }
      </div>
    </div>
  );
};

export default SpotToolbar;
