import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  UmbrellaInfo as UmbrellaInfoView,
  MoveUmbrella as MoveUmbrellaView,
  FunctionsList as FunctionsListView,
  CustomerSuccess as CustomerSuccessView,
  SpotRegister as SpotRegisterView,
  KasaLog as KasaLogView,
  GeneralOffice as GeneralOfficeView,
  KasaRegister as KasaRegisterView,
  PushNotification as PushNotificationView,
} from "./views";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/functions" />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />

      <RouteWithLayout
        component={UmbrellaInfoView}
        exact
        layout={MinimalLayout}
        path="/maintenance/umbrellas"
      />
      <RouteWithLayout
        component={MoveUmbrellaView}
        exact
        layout={MinimalLayout}
        path="/maintenance/umbrellas/move"
      />

      <RouteWithLayout
        component={KasaLogView}
        exact
        layout={MinimalLayout}
        path="/maintenance/umbrella/log"
      />

      <RouteWithLayout
        component={FunctionsListView}
        exact
        layout={MinimalLayout}
        path="/functions"
      />

      <RouteWithLayout
        component={CustomerSuccessView}
        exact
        layout={MinimalLayout}
        path="/cs"
      />


      <RouteWithLayout
        component={SpotRegisterView}
        exact
        layout={MinimalLayout}
        path="/spot/register"
      />
      <RouteWithLayout
        component={GeneralOfficeView}
        exact
        layout={MinimalLayout}
        path="/office"
      />
      
      <RouteWithLayout
        component={KasaRegisterView}
        exact
        layout={MinimalLayout}
        path="/maintenance/umbrella/register"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />

      <RouteWithLayout
        component={PushNotificationView}
        exact
        layout={MinimalLayout}
        path="/pushNotification"
      />

      {/* <Redirect to="/" /> */}

    </Switch>
  );
};

export default Routes;
