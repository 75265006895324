import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UmbrellaToolbar, UmbrellaList } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = () => {
  const classes = useStyles();

  const [scd, setScd] = useState(1)
  const [stockInfo, setStockInfo] = useState({count: 0, stocks:[]})

  return (
    <div className={classes.root}>
      <h3>傘ストック一覧</h3>
      <UmbrellaToolbar scd={scd} setScd={setScd} setStockInfo={setStockInfo}/>
      <div className={classes.content}>
        <UmbrellaList stockInfo={stockInfo} />
      </div>
    </div>
  );
};

export default UserList;
