import { AmplifySignIn } from '@aws-amplify/ui-react';
import React from 'react';
import { withRouter } from 'react-router-dom';


const SignIn = () => {

  return (
    <AmplifySignIn
      slot="sign-in"
      hideSignUp
      submitButtonText='ログイン'
      headerText='ログイン'
      formFields={[
        {
          type: "username",
          label: "ユーザー名",
          required: true,
          placeholder: 'ユーザー名を入力してください'
        },
        {
          type: "password",
          label: "パスワード",
          required: true,
          placeholder: 'パスワードを入力してください'
        }
      ]}
    />
  );
};


export default withRouter(SignIn);
