import { Card, makeStyles, TextField } from "@material-ui/core";
import { title } from "process";
import React from "react";
import theme from "theme";

const useStyles = makeStyles({
  formControl: {
    marginBottom: "20px",
  },
  textField: {
    marginBottom: "20px",
  },
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
});

type PushSettingsProps = {
  // プッシュ通知のタイトル
  title: string;
  // プッシュ通知の本文
  body: string;
  // プッシュ通知のID
  notificationId: string;
  // プッシュ通知のタイトル変更時のコールバック関数
  onTitleChange: (title: string) => void;
  // プッシュ通知の本文変更時のコールバック関数
  onBodyChange: (body: string) => void;
  // プッシュ通知のID変更時のコールバック関数
  onNotificationIdChange: (notificationId: string) => void;
};

export default function PushSettings(props: PushSettingsProps) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <h2>Push通知文の設定</h2>
      <TextField
        className={classes.textField}
        label="プッシュ通知のタイトル"
        value={props.title}
        onChange={(e) => props.onTitleChange(e.target.value)}
        fullWidth
      />
      <TextField
        className={classes.textField}
        label="プッシュ通知の本文"
        value={props.body}
        onChange={(e) => props.onBodyChange(e.target.value)}
        placeholder="プッシュ通知する文言を入力してください。"
        fullWidth
        multiline
        rows={2}
        rowsMax={50}
      />
      <TextField
        className={classes.textField}
        label="プッシュ通知のID(送った数などの識別に利用します)"
        value={props.notificationId}
        onChange={(e) => props.onNotificationIdChange(e.target.value)}
        fullWidth
      />
    </Card>
  );
}
