import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";
import { ConfirmDialog } from "../../../../../components";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { timeToStr } from "../../../../../utils/date";

const API_HOST = process.env.REACT_APP_API_SERVER_HOST;

const IS_SUCCESS_COLOR = "#757575";

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: "10px"
  },
  success: {
    backgroundColor: IS_SUCCESS_COLOR
  }
}));

const TransactionTable = props => {
  const { user, scd, transactions, setTransactions } = props;

  const [ opens, setOpens ] = useState([]);

  const classes = useStyles();

  const setOpensList = (len) => {
    const opensList = [];
    for (let i = 0; i < len; i++) {
      opensList.push(false);
    };
    setOpens(opensList);
  }

  useEffect(() => {
    if (user.ikasa_id === undefined) return;

    axios
      .get(`${API_HOST}/service/v1/return/confirm/${user["ikasa_id"]}`)
      .then(res => {
        const grantData = {
          isSuccess: false,
          isFailed: false,
          error: ""
        };

        let transactions = res.data.map(transaction => ({
          ...transaction,
          ...grantData
        }));
        setOpensList(transactions.length);
        transactions.sort((i, j) => i.ID - j.ID);
        setTransactions(transactions);
      })
      .catch(err => {
        alert(err.response.data);
      });
    // eslint-disable-next-line
  }, [user]);

  const handleClickReturn = index => {
    if (transactions[index].isSuccess) return;

    axios
      .post(`${API_HOST}/service/v1/cs/return`, {
        ikasa_id: user.ikasa_id,
        scd: scd,
        ksid: transactions[index].ksid
      })
      .then(res => {
        const cpTransactions = transactions.slice();
        cpTransactions[index].isSuccess = true;
        setTransactions(cpTransactions);
      })
      .catch(err => {
        const cpTransactions = transactions.slice();
        cpTransactions[index].isFailed = true;
        cpTransactions[index].error = err.response.data['message'];
        setTransactions(cpTransactions);
        alert("返却処理に失敗しました。");
      });
  };

  const updateOpens = (index, value) => {
    const cpOpens = opens.slice();
    cpOpens[index] = value;
    setOpens(cpOpens);
  }
  
  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>傘ID</TableCell>
              <TableCell>レンタル開始時間</TableCell>
              <TableCell>レンタル開始スポットコード</TableCell>
              <TableCell>レンタルID</TableCell>
              <TableCell>トークンID</TableCell>
              <TableCell>エラー</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction, index) => (
              <React.Fragment key={transaction['transaction_id']}>
                <TableRow
                  hover={!transaction.isSuccess}
                  role="checkbox"
                  tabIndex={-1}
                  className={transaction.isSuccess ? classes.success : ""}
                  onClick={() => 
                    transaction.isSuccess ? 
                    '' :
                    updateOpens(index, true)
                  }
                >
                  <TableCell align="left">{transaction.ksid}</TableCell>
                  <TableCell align="left">
                    {timeToStr(transaction.rented_at)}
                  </TableCell>
                  <TableCell align="left">{transaction.rented_scd}</TableCell>
                  <TableCell align="left">{transaction.transaction_id}</TableCell>
                  <TableCell align="left">{transaction.charge_id}</TableCell>
                  <TableCell align="left">{transaction.err}</TableCell>
                </TableRow>
                <ConfirmDialog
                  onClose={() => updateOpens(index, false)}
                  open={opens[index]}
                  func={() => handleClickReturn(index)}
                  title={`傘ID：${transaction.ksid}を返却しますか？`}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TransactionTable;
