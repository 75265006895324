// pushNotification.tsx
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  pushNotifiacation_api,
  PushNotificationRequest,
} from "functions/pushNotifiacation_api";
import React, { useState } from "react";
import theme from "theme";
import {
  PushContents,
  PushSettings,
  TargetSettings,
  ResultDialog,
} from "./components";

const useStyles = makeStyles({
  formControl: {
    marginBottom: "20px",
  },
  textField: {
    marginBottom: "20px",
  },
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
});

export default function PushNotification() {
  const classes = useStyles();
  const [targetIds, setTargetIds] = useState<String[]>([]);
  const [environment, setEnvironment] = useState<string>("dev");
  const [priority, setPriority] = useState<string>("high");
  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [notificationId, setNotificationId] = useState<string>("");
  const [headerImageUrl, setHeaderImageUrl] = useState<string>("");
  const [pageTitle, setPageTitle] = useState<string>("");
  const [pageSubtitle, setPageSubtitle] = useState<string>("");
  const [pageBody, setPageBody] = useState<string>("");
  const [buttonText, setButtonText] = useState<string>("");
  const [buttonType, setButtonType] = useState<string>("");
  const [buttonLink, setButtonLink] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [dialogType, setDialogType] = useState<string>("");

  // フォームをすべてクリア
  const clearForm = () => {
    setTitle("");
    setBody("");
    setNotificationId("");
    setHeaderImageUrl("");
    setPageTitle("");
    setPageSubtitle("");
    setPageBody("");
    setButtonText("");
    setButtonType("");
    setButtonLink("");
  };

  const handleTargetIdsChange = (newTargetIds: String[]) => {
    setTargetIds(newTargetIds);
  };

  const handleSendNotification = async () => {
    const request: PushNotificationRequest = {
      environment: environment,
      title: title,
      body: body,
      priority: priority,
      id: notificationId,
      page: {
        type: "page",
        image: headerImageUrl,
        title: pageTitle,
        subtitle: pageSubtitle,
        content: pageBody,
        button: {
          text: buttonText,
          action: {
            link: buttonLink,
            type: buttonType,
          },
        },
      },
      ikasa_id_list: targetIds,
    };
    try {
      // 1000件を超える場合は警告ダイアログを表示
      if (request.ikasa_id_list.length > 1000) {
        setDialogType("警告");
        setDialogMessage(
          "送信対象のアイカサIDが1000件を超えていますので、送信できません。"
        );
        setOpenDialog(true);
        return;
      }
      // TODO:BEが大量送信に対応したらコメントアウトを外す
      // let response = await sendChunkPushNotifiacation(request);
      let response = await pushNotifiacation_api(request);
      console.log(response);
      if (response.push_count === 0) {
        setDialogType("エラー");
        setDialogMessage(
          "Push通知の送信に失敗しました。\n 送信対象のユーザーが存在しないか、送信対象が指定されていません。"
        );
        setOpenDialog(true);
        return;
      }
      setDialogType("結果");
      setDialogMessage(response.push_count + " 件Push通知を送信しました。");
      setOpenDialog(true);
      clearForm();
    } catch (err) {
      setDialogType("エラー");
      setDialogMessage("Push通知の送信に失敗しました。\n " + err);
      setOpenDialog(true);
      throw err;
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className={classes.root}>
      <h1>Push 通知</h1>
      <TargetSettings
        environment={environment}
        priority={priority}
        targetIds={targetIds}
        onEnvironmentChange={setEnvironment}
        onPriorityChange={setPriority}
        onTargetIdsChange={handleTargetIdsChange}
      />
      <PushSettings
        title={title}
        body={body}
        notificationId={notificationId}
        onTitleChange={setTitle}
        onBodyChange={setBody}
        onNotificationIdChange={setNotificationId}
      />
      <PushContents
        headerImageUrl={headerImageUrl}
        pageTitle={pageTitle}
        pageSubtitle={pageSubtitle}
        pageBody={pageBody}
        buttonText={buttonText}
        buttonType={buttonType}
        buttonLink={buttonLink}
        onHeaderImageUrlChange={setHeaderImageUrl}
        onPageTitleChange={setPageTitle}
        onPageSubtitleChange={setPageSubtitle}
        onPageBodyChange={setPageBody}
        onButtonTextChange={setButtonText}
        onButtonTypeChange={setButtonType}
        onButtonLinkChange={setButtonLink}
      />
      <Button
        style={{ marginTop: "20px" }}
        variant="contained"
        color="primary"
        onClick={handleSendNotification}
      >
        プッシュ通知を送信
      </Button>
      <ResultDialog
        open={openDialog}
        onClose={handleCloseDialog}
        message={dialogMessage}
        dialogType={dialogType}
      />
    </div>
  );
}
