import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { timeToStr } from '../../../../../utils/date'


const useStyles = makeStyles(theme => ({
  table: {
    marginTop: '10px',
  }
}));

const UserDetail = (props: any) => {
  const { user,payment } = props;
  const classes = useStyles();
  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>項目</TableCell>
            <TableCell>情報</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">ユーザーID</TableCell>
            <TableCell align="left">{user.ikasa_id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">プラン</TableCell>
            <TableCell align="left">{user.plan_type}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">最終更新日</TableCell>
            <TableCell align="left">{timeToStr(user.UpdatedAt)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">LINE ID</TableCell>
            <TableCell align="left">{user.line_id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">LINE レッグキー</TableCell>
            <TableCell align="left">{payment.line_regkey ? '有り' : '無し'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">最寄駅</TableCell>
            <TableCell align="left">{user.home_station}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">勤務先駅</TableCell>
            <TableCell align="left">{user.work_station}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">チュートリアル完了済み</TableCell>
            <TableCell align="left">{user.tutorial_flag ? 'Yes' : 'No'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">条項承認済み</TableCell>
            <TableCell align="left">{user.terms_agree_flag ? 'Yes' : 'No'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">旧ユーザーID</TableCell>
            <TableCell align="left">{user.previsou_user_id}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserDetail;