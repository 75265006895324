import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  icon: {
    fontSize: '4rem',
    marginBottom: theme.spacing(2),
    color: theme.palette.success.main,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

interface SuccessDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
}

const SuccessDialog: React.FC<SuccessDialogProps> = ({ open, onClose, title }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>成功</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <CheckCircleOutlineIcon className={classes.icon} />
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
