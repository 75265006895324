import React from "react";
import {
  Card,
  List,
  ListItem,
  ListItemText,
  ListSubheader
} from "@material-ui/core";

const UmbrellaList = props => {
  const { className, stockInfo, ...rest } = props;

  return (
    <Card {...rest}>
      <List subheader={<li />}>
        <ListSubheader>合計： {stockInfo.count}　本</ListSubheader>
        {stockInfo.stocks.map(umbrella => (
          <ListItem key={umbrella}>
            <ListItemText>{umbrella}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default UmbrellaList;
