import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { generateMonthList, getThisMonth } from '../../../../../utils/date'



const ReturnHistoryToolbar = props => {
  const { setMonth } = props;
  
  const monthList = generateMonthList();

  return (
    <Select
      defaultValue={getThisMonth()}
      onChange={e => setMonth(e.target.value)}
    >
      {monthList.map((month, index) => (
        <MenuItem key={index} value={month.valueYearMonth}>
          {month.viewYearMonth}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ReturnHistoryToolbar;
